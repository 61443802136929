<template>
  <HeaderSection :show-overlay="showOverlay" @mode-change="handleModeChange" />
  <article id="pages">
    <router-view :key="$route.fullPath" @mode-change="handleModeChange" />
  </article>
  <FloatingMenuBox @mode-change="handleModeChange" />
  <FooterSection />
  <Login :mode="mode" @mode-change="handleModeChange" />
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import HeaderSection from '@/components/layout/Header.vue';
import FooterSection from '@/components/layout/Footer.vue';
import FloatingMenuBox from '@/components/layout/FloatingMenuBox.vue';
import Login from '@/components/auth/Login.vue';

const store = useStore();
const showOverlay = ref(false);
const currentUser = computed(() => store.state.auth.user);
const mode = ref('none');

const handleModeChange = (newMode) => {
  mode.value = newMode;
  // todo: showOverlay -> header 에서 분리하기 (loading 포함 전역 객체로 관리)
  showOverlay.value = (newMode !== 'none');
  if (showOverlay.value) { // 기존 newMode === "artwork"
    document.body.classList.add('on');
  } else {
    document.body.classList.remove('on');
  }
};

const showArtistBoard = computed(() => {
  return currentUser.value && currentUser.value.roles && currentUser.value.roles.includes('ROLE_ARTIST');
});

const redirectIfWww = () => {
  const host = window.location.hostname;
  const url = window.location.href;

  if (host.startsWith('www.')) {
    const newUrl = url.replace('://www.', '://');
    window.location.replace(newUrl);
  }
};

onMounted(() => {
  redirectIfWww();
});
</script>

<style>
/* 퍼블 끝나면 적용하기기 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 임시 스피너 */
.btn-spinner {
  width: 24px;
  height: 24px;
  border: 3px solid #F7BE17;
  border-top: 3px solid #ffffff00;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-check {
  font-size: 24px;
  color: #F7BE17;
  font-weight: bold;
}

.bbs .bbs-view .view-content p {
  white-space: pre-line;
}
</style>
