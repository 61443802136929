<template>
  <div v-show="isHome && isVisible" class="floating-menu-box" :style="{ zIndex: isModalOpen ? 1001 : '' }">
    <ul>
      <li>
        <button type="button" class="btn-floating" :class="{close : isModalOpen}"  @click="toggleModal(true)">
          <img src="@/assets/images/icon/ico_cs.svg" alt="">
        </button>
        <button  type="button" class="btn-floating" :class="{close : !isModalOpen}"  @click="toggleModal(false)">
          <img src="@/assets/images/icon/ico_close.png" alt="">
        </button>
      </li>
    </ul>
  </div>

  <button v-show="isVisible" type="button" class="btn-floating top" @click="scrollToTop">
    <img src="@/assets/images/icon/ico_top.svg" alt="" />
    TOP
  </button>

  <CSModal v-show="isModalOpen" />
</template>

<script setup>
import { ref, defineEmits, onMounted, onUnmounted, watch  } from "vue";
import { useRoute } from 'vue-router';
import CSModal from "@/components/fab/CSModal.vue";

const route = useRoute();
const emit = defineEmits(["mode-change"]);

const isModalOpen = ref(false);

const toggleModal = (status) => {
  isModalOpen.value = status;
  emit("mode-change", status ? "cs-modal-open" : "none");
};

// 스크롤 상태를 관리(사용안하면 추후 삭제)
const isVisible = ref(false);  

const isHome = ref(false);

watch(
  () => route.name,
  (newName) => {
    if (newName === 'Home') {
      isHome.value = true;
    } else {
      isHome.value = false;
    }
  },
  { immediate: true } // 처음 컴포넌트가 마운트될 때도 이름 확인
);


const handleScroll = () => {
  isVisible.value = window.scrollY > 100; // 스크롤이 100px 이상일 때 버튼 보이기
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" }); // 스크롤 최상단 이동
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll); // 스크롤 이벤트 등록
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll); // 스크롤 이벤트 제거
});
</script>