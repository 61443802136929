<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ARTICLE -->
    <div class="article">
      <div class="layout">
        <div class="box">
          <ArticleSnb />
          <div class="content-panel">
            <div class="special-view-wrap">
              <div class="special-view-title">
                <h3>{{ special.title }}
                  <span class="sub">{{ special.subTitle }}</span>
                </h3>
                <ArticleButtons :isLike="special.isLike" :uuid="special.uuid" :likeType="special.likeType" />
              </div>
              <div class="special-view-date">
                <span>{{ special.createdAt }}</span>
              </div>
            </div>

            <div class="view-type3">
              <figure v-if="special.mainImageUrl">
                <img :src="special.mainImageUrl" alt="" />
              </figure>
              <div v-if="special.mainContent" class="txt-box" style="white-space: pre-line;">
                {{ special.mainContent }}
              </div>
              <template v-for="(content, index) in special.contents" :key="content.uuid">
                <div :class="['box2', { reverse: index % 2 === 1 }]">
                  <div v-if="content.imageUrl" class="img">
                    <img :src="content.imageUrl" :alt="content.title" />
                  </div>
                  <div class="txt">
                    <h3 v-if="content.title">{{ content.title }}</h3>
                    <p v-if="content.content" class="txt-box" style="white-space: pre-line;">
                      {{ content.content }}
                    </p>
                  </div>
                </div>
              </template>
              <div class="view-btn-wrap">
                <a href="#"> <!-- todo: artist url 연결-->
                  <span>go to art&artists</span>
                  <img src="@/assets/images/icon/ico_right.svg" alt="">
                </a>
              </div>

              <!-- 하단 white divider -->
              <!-- <div class="white-divider"></div> -->
              <div class="view-writer">
                <div class="writer-wrap">
                  <div class="writer-img-box">
                    <figure><img v-if="special.writerImageUrl" :src="special.writerImageUrl" alt="writer image" /></figure>
                  </div>
                  <div class="writer-txt-box">
                    <div class="writer-title">
                      <span>writer</span>
                      <span class="writer">{{ special.writer }}</span>
                    </div>
                    <p>
                      {{ special.writerMessage }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { apiClient } from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import ArticleButtons from "@/components/article/ArticleButtons.vue";

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const loading = ref(true);
const error = ref(null);
const special = ref(null);

// const formattedDate = computed(() => {
//   if (!special.value || !special.value.createdAt) {
//     return "";
//   }
//   const date = new Date(special.value.createdAt);
//   const year = date.getFullYear();
//   const month = String(date.getMonth() + 1).padStart(2, "0");
//   const day = String(date.getDate()).padStart(2, "0");
//   const hours = String(date.getHours()).padStart(2, "0");
//   const minutes = String(date.getMinutes()).padStart(2, "0");

//   // 'YYYY-MM-DD HH:mm' 형식으로 반환
//   return `${year}-${month}-${day} ${hours}:${minutes}`;
// });

onMounted(async () => {
  try {
    const response = await apiClient.get(`/v1/contents/articles/specials/${articleUuid.value}`);
    special.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>
