<template>
  <!-- s::MAIN -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <main v-else>
    <div class="main-text">
      <template v-if="homeMainBanner.mediaType === 'MP4'">
        <video autoplay muted loop playsinline @loadeddata="onImageLoaded" style="height: 100%;">
          <source :src="homeMainBanner.imageUrl" type="video/mp4">
        </video>
      </template>
      <template v-else>
        <img :src="homeMainBanner.imageUrl" @load="onImageLoaded" alt="Banner Image" style="width: 100%; height: 100%; object-fit: cover;">
      </template>
    </div>

    <section v-if="artists.length" class="section01">
      <div class="art-box">
        <div class="title-box">
          <h2>ART & ARTISTS</h2>
          <div class="button-box">
            <router-link :to="{ name: 'ArtistList' }">See More</router-link>
          </div>
        </div>

        <div class="slide-container">
          <div class="slide-title">
            <h3>Artists Of<br> the Month</h3>
          </div>
          <div class="slide-box">  <!-- class: pc, mo-->
            <div class="tab-cont" v-for="(artist, index) in artists" :key="artist.uuid">
              <div class="inner">

                <div class="artists">
                  <div class="artists-txt">
                    <h3>
                      {{ artist.nameEn }}
                      <HeartButton :isLike="artist.isLike" :uuid="artist.uuid" :likeType="artist.likeType"/>
                    </h3>
                    <p>{{ artist.nameKo }}</p>
                  </div>
                  <div class="img-box">
                    <figure>
                      <img :src="artist.imageUrl" alt="">
                    </figure>
                  </div>
                </div>

                <div class="slide-wrap">
                  <!-- Swiper -->
                  <div class="swiper " :class="'mySwiper-' + index">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide" v-for="artWork in artist.artWorks" :key="artWork.uuid">
                        <a href="#">
                          <img :src="artWork.imageUrl">
                          <div class="txt">
                            <h4>{{ artWork.title }}</h4>
                            <p>{{ artWork.material }}</p>
                            <span>{{ artWork.year }}</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="homeSubBanner" class="section02">
      <div v-if="homeSubBanner" class="visual-wrap">
        <figure>
          <template v-if="homeSubBanner.mediaType === 'MP4'">
            <video autoplay muted loop playsinline>
              <source :src="homeSubBanner.imageUrl" type="video/mp4">
            </video>
          </template>
          <template v-else>
            <img :src="homeSubBanner.imageUrl" alt="Banner Image">
          </template>
        </figure>
      </div>
    </section>

    <section v-if="articleSpecial" class="section03">
      <div class="article-title">
        <h3>ARTICLE</h3>
        <ul>
          <li>
            <router-link :to="{ name: 'Special' }">SPECIAL</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Exhibition' }">EXHIBITION</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Event' }">EVENT</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'News' }">NEWS</router-link>
          </li>
        </ul>
      </div>

      <div class="article-wrap">
        <router-link :to="{ name: getRouteNameByValue(articleSpecial.likeType), params: { uuid: articleSpecial.uuid } }">
          <figure><img :src="articleSpecial.previewImageUrl" alt=""></figure>
        </router-link>
        <div class="article-txt">
          <div class="sub-title">
            <span>{{ articleSpecial.likeType }}</span>
            <HeartButton :isLike="articleSpecial.isLike" :uuid="articleSpecial.uuid" :likeType="articleSpecial.likeType"/>
          </div>
          <div class="explaint-txt">
            <div class="artists-txt">
              <h4>{{ articleSpecial.subTitle }}</h4>
              <span class="sub">{{ articleSpecial.title }}</span>
            </div>
            <p>{{ articleSpecial.mainContent }}</p>
          </div>
        </div>
      </div>
    </section>

    <section v-if="articles.length" class="section04">
      <div class="swiper mySwiper4">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="article in articles" :key="article.uuid">
            <router-link :to="{ name: getRouteNameByValue(article.likeType), params: { uuid: article.uuid } }">
              <figure><img :src="article.imageUrl" alt=""></figure>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
  <!-- e::MAIN -->
</template>

<script setup>
import {nextTick, onMounted, ref} from 'vue';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import {apiClient} from '@/services/auth-header';
import router from "@/router";
import {useAuthStore} from '@/store/auth.module';
import {useUnreadStore} from '@/store/unread.module';
import HeartButton from "@/components/ui/HeartButton.vue";
import {ARTICLE_LIKE_TYPE} from '@/common/common-enum';

const authStore = useAuthStore();
const unreadStore = useUnreadStore();

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const artists = ref([]);
const homeMainBanner = ref(null);
const homeSubBanner = ref(null);
const articles = ref([]);
const articleSpecial = ref(null);
const activeTab = ref(1);
let isMobile = ref(false);
let perView = ref(4);

let tempItems = null;
const onImageLoaded = () => {
  nextTick(() => {
    handleDataLoad();
  });
};

const handleDataLoad = async () => {
  if (tempItems) {
    // 순차적으로 데이터를 처리
    artists.value = await getArtists();
    homeSubBanner.value = await getHomeSubBanner();
    articleSpecial.value = await getArticleSpecial();
    articles.value = await getArticles();
    await initSwiper();
    tempItems = null;
  }
};

const getArtists = async () => {
  return tempItems.artists;
};

const getHomeSubBanner = async () => {
  return tempItems.homeSubBanner;
};

const getArticleSpecial = async () => {
  return tempItems.articleSpecial;
};

const getArticles = async () => {
  return tempItems.articles;
};

const getRouteNameByValue = (value) => {
  const item = ARTICLE_LIKE_TYPE.find(item => item.value === value);
  return item ? item.routeName : null; // 값이 없으면 null 반환
};

// Swiper 초기화 함수
const initSwiper = async () => {
  console.log("initSwiper")
  await nextTick();
  artists.value.forEach((artist, index) => {
    new Swiper(`.mySwiper-${index}`, {
      observer: true,
      observeParents: true,
      spaceBetween: 18,
      slidesPerView: 'auto',
      autoResize: false,
      loop: false,
      freeMode: true,
      breakpoints: {
        375: {
          spaceBetween: 11,
        },
      },
    });
  });

  new Swiper(".mySwiper4", {
    observer: true,
    observeParents: true,
    spaceBetween: 18,
    slidesPerView: 'auto',
    autoResize: false,
    loop: true,
    freeMode: false,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
  });
};

// 탭 변경 함수
const setActiveTab = (tabId) => {
  activeTab.value = tabId;
  initSwiper(); // 탭 변경 시 Swiper 초기화
};

const loadItems = async () => {
  try {
    const response = await apiClient.get('/v1/home');
    // console.log("response.data", response.data)
    homeMainBanner.value = response.data.homeMainBanner;
    tempItems=response.data;
    // localStorage.setItem("menu", JSON.stringify(response.data.menu));
  } catch (err) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

// const goArticler = () => {
//   alert("서비스 준비중입니다.");
// };

// SNS 로그인 함수
// const snsLogin = async () => {
//   const urlParams = new URLSearchParams(window.location.search);
//   const accessToken = {"accessToken": urlParams.get('accessToken')};
//   const response = await authStore.snsLogin(accessToken);
//   const role = response.roles[0];
//   const redirectPath = role === 'ROLE_ADMIN' ? '/admin' : '/';
//   await router.push(redirectPath);
// };

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

onMounted(async () => {
  checkMobile();
  // await fetchBanners();
  await loadItems();
  unreadStore.fetchAllUnreadCounts();
});

</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 비디오가 비율에 맞춰 크기 맞추기 */
}

.section04 .mySwiper4 .swiper-wrapper .swiper-slide figure img {
  aspect-ratio: 287.73 / 394.6;
  object-fit: cover;
  border-radius: 20px;
}


/*.video-container {
  display: flex;
  justify-content: center;
}

video {
  width: 1200px;
}
.section02 .layout li {
  cursor: pointer;
}*/
</style>