<template>
  <div v-if="totalPages > 1 && isMobile">
    <div class="spinner-wrapper">
      <div v-show="isFetching" class="btn-spinner"></div>
    </div>
    <div ref="scrollTrigger" class="scroll-trigger"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import { useDeviceStore } from "@/store/deviceStore";
const deviceStore = useDeviceStore();
const isMobile = computed(() => deviceStore.isMobile);

const scrollTrigger = ref(null);
const observer = ref(null);

const props = defineProps({
  onReachEnd: {
    type: Function,
    required: true
  },
  currentPage: {
    type: Number,
    required: true
  },
  totalPages: {
    type: Number,
    required: true
  },
  isFetching: {
    type: Boolean,
    required: true
  }
});

function setupInfiniteScroll() {
  if (!scrollTrigger.value || !isMobile.value) return;

  if (observer.value) observer.value.disconnect();

  observer.value = new IntersectionObserver(async ([entry]) => {
    // console.log("📌 현재 페이지:", props.currentPage, "/", props.totalPages);
    if (
      entry.isIntersecting &&
      props.currentPage + 1 < props.totalPages &&
      !props.isFetching
    ) {
      // console.log("✅시작작 스크롤 끝에 도달함! 다음 페이지 로드...");
      await props.onReachEnd(props.currentPage + 1, true);  // 부모에서 전달된 onReachEnd 호출
      // console.log("✅끝끝 스크롤 끝에 도달함! 다음 페이지 로드...");
    }
  }, { threshold: 0.5, rootMargin: "0px" });

  observer.value.observe(scrollTrigger.value);
}

onMounted(() => {
  setupInfiniteScroll();
});

onUnmounted(() => {
  if (observer.value) {
    observer.value.disconnect();
  }
});
</script>

<style scoped>
.scroll-trigger {
  width: 100%;
  height: 10px;
  /* 감지할 최소 높이 */
  background: transparent;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
