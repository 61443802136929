import MyProfile from "@/views/user/Profile.vue";
import ArtWork from "@/views/user/ProfileArtWork.vue";
import Portfolio from "@/views/user/Portfolio.vue";
import Signup from "@/views/Signup.vue";
import MyPage from "@/views/user/MyPage.vue";
import PasswordUpdate from "@/views/user/PasswordUpdate.vue";
import VerifyResult from "@/components/auth/VerifyResult.vue";

export const userRoutes = [
    {
        path: '/auth/signup',
        component: Signup,
    },
    {
        path: '/auth/verifyResult',
        component: VerifyResult,
    },
    {
        path: '/my/info',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/my/info/apply-artist',
        name: 'ApplyArtistPage',
        component: MyPage,
        meta: { isApplyArtist: true } 
    },
    {
        path: '/my/password',
        name: 'PasswordUpdate',
        component: PasswordUpdate,
    },
    {
        path: '/my',
        name: 'ART & ARTISTS',
        children: [
            {
                path: '/my/artist/profile',
                name: 'MyProfile',
                component: MyProfile,
            },
            {
                path: '/my/artist/artwork',
                name: 'ProfileArtwork',
                component: ArtWork,
            },
            {
                path: '/my/artist/portfolio',
                name: 'Portfolio',
                component: Portfolio,
            },
        ],
    },
];
