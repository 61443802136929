<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ARTICLE -->
    <div class="article">
      <div class="layout">
        <div class="box">
          <ArticleSnb />
          <div class="content-panel">
            <div class="view-type1">
              <ArticleButtons :isLike="exhibition.isLike" :uuid="exhibition.uuid" :likeType="exhibition.likeType" />
              <div class="img">
                <img :src="exhibition.imageUrl" alt="">
              </div>
              <div class="info-box">
                <h3>{{ exhibition.title }} </h3>
                <dl>
                  <dt>기간</dt>
                  <dd>{{ getPeriod() }}</dd>
                  <template v-if="getTime()">
                    <dt>시간</dt>
                    <dd>{{ getTime() }}</dd>
                  </template>
                  <dt>장소</dt>
                  <dd>{{ exhibition.location }}
                    <img src="@/assets/images/icon/kakaomap_basic.png" alt="카카오맵"
                      @click="openKakaoMap(exhibition.location)"
                      style="width: 24px; cursor: pointer; margin-bottom: -4px; margin-left: 8px;" />
                  </dd>
                  <dt>문의</dt>
                  <dd>{{ exhibition.contactPhoneNum }}</dd>
                  <dd v-if="exhibition.contactEmail">{{ exhibition.contactEmail }}</dd>
                  <template v-if="exhibition.originLink">
                    <dt>링크</dt>
                    <dd>
                      <a :href="exhibition.originLink" target="_blank" class="link">{{ exhibition.originLink }}</a>
                    </dd>
                  </template>
                  <dd>
                    <p class="txt" style="white-space: pre-line;">
                      {{ exhibition.content }}
                    </p>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { apiClient } from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import ArticleButtons from "@/components/article/ArticleButtons.vue";

const route = useRoute();
const articleUuid = ref(route.params.uuid);
const loading = ref(true);
const error = ref(null);
const exhibition = ref(null);

const getPeriod = () => {
  let period = exhibition.value.periodFrom + ' ~ ' + exhibition.value.periodTo;
  if (exhibition.value.periodMemo) {
    period += ' (' + exhibition.value.periodMemo + ')';
  }
  return period;
}

const getTime = () => {
  let time = '';
  if (exhibition.value.timeFrom) {
    time += exhibition.value.timeFrom;
  }

  if (exhibition.value.timeTo) {
    time += ' ~ ' + exhibition.value.timeTo;
  }

  if (exhibition.value.timeMemo) {
    time += ' (' + exhibition.value.timeMemo + ')';
  }

  return time;
}

const openKakaoMap = (address) => {
  if (!address) return;
  const encodedAddress = encodeURIComponent(address);
  const kakaoMapUrl = `https://map.kakao.com/?q=${encodedAddress}`;
  window.open(kakaoMapUrl, "_blank");
};

onMounted(async () => {
  try {
    const response = await apiClient.get(`/v1/contents/articles/exhibitions/${articleUuid.value}`);
    exhibition.value = response.data;
  } catch (error) {
    error.value = error; // 에러를 저장합니다.
  } finally {
    loading.value = false; // 작업이 끝난 후에는 로딩 상태를 끝냅니다.
  }
});
</script>