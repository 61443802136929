<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div class="content-panel">
            <div class="inquiry">
              <h2 class="page-title">1:1 문의</h2>
              <form>
                <fieldset>
                  <div class="form-box">
                    <div class="field-row">
                      <div class="label">
                        <span>분류</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <select v-model="inquiry.type" @change="selectedCategory" style="width: 300px;">
                            <option value="" disabled>카테고리 선택</option>
                            <option v-for="type in INQUIRY_TYPE" :value="type.value">{{ type.label }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>제목</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" v-model="inquiry.title" placeholder="제목을 입력해주세요.">
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>내용</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <textarea v-model="inquiry.content" placeholder="내용을 입력해주세요."></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>
                          첨부파일
                        </span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-wrap">
                          <div class="file-box">
                            <label for="fileInput" class="file-label">
                              <span>업로드</span>
                            </label>
                            <input type="file" id="fileInput" class="file-input" @change="loadFile" accept="image/*"
                              multiple />
                            <div class="file-txt">
                              <span class="file-sub">10MB 이하 파일 업로드 가능</span>
                            </div>
                          </div>
                          <div class="file-names">
                            <template v-for="(file, index) in selectedFiles" :key="index">
                              <div class="file-name" :class="'file-item-' + index">
                                <span>{{ file.name }}</span>
                                <button @click="deleteFile(file.name, index)" type="button">
                                  <img src="@/assets/images/icon/ico_close_gray.svg"
                                    style="width: 18px; padding-left: 5px" alt="" />
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>이메일</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" v-model="inquiry.email" placeholder="답변을 받아볼 이메일 주소를 적어주세요.">
                        </div>
                      </div>
                    </div>
                    <div class="field-row">
                      <div class="label">
                        <span>전화번호</span>
                      </div>
                      <div class="cmm-form">
                        <div class="input-box">
                          <input type="text" v-model="inquiry.phoneNum" placeholder="휴대폰 번호를 적어주세요.">
                        </div>
                      </div>
                    </div>
                    <div class="button-box">
                      <button type="button" @click="saveInquiry">
                        등록하기
                      </button>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import router from "@/router";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import { apiClient } from "@/services/auth-header";
import { INQUIRY_TYPE } from "@/common/common-enum";

const loading = ref(true);
const error = ref(null);
const API_URL = "/support"
const inquiry = ref({
  type: "",
  title: "",
  content: "",
  email: "",
  phoneNum: "",
});

const selectedFiles = ref([]);
const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const selectedCategory = (e) => {
  inquiry.value.type = e.target.value;
};

const formatPhoneNumber = (event) => {
  let cleaned = inquiry.value.phoneNum.replace(/\D/g, '')
  let formatted = ''

  if (cleaned.length > 3) {
    formatted = cleaned.slice(0, 3) + '-'
    if (cleaned.length > 7) {
      formatted += cleaned.slice(3, 7) + '-'
      formatted += cleaned.slice(7, 11)
    } else {
      formatted += cleaned.slice(3, 7)
    }
  } else {
    formatted = cleaned
  }

  inquiry.value.phoneNum = formatted
};

const loadFile = (event) => {
  const files = event.target.files;
  // 이미 두 개의 파일이 선택되었으면 추가 파일을 선택하지 못하도록 제한
  if (files.length + selectedFiles.value.length > 2) {
    alert("최대 2개의 파일만 업로드 가능합니다.");
    return;
  }

  // 파일을 배열에 추가
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const fileSizeInMB = file.size / (1024 * 1024);

    // 파일 크기 제한: 10MB 이하만 허용
    if (fileSizeInMB > 10) {
      alert("10MB 이하의 파일만 업로드할 수 있습니다.");
      continue; // 이 파일은 무시하고 다음 파일로 진행
    }

    selectedFiles.value.push(file);
  }
};

const deleteFile = (fileName, index) => {
  if (confirm(`${fileName}을 삭제 하시겠습니까?`)) {
    selectedFiles.value.splice(index, 1);
  }
};

const loadProfile = async () => {
  try {
    const response = await apiClient.get('/v1/users/me');
    inquiry.value.email = response.data.email;
    inquiry.value.phoneNum = response.data.phoneNum;
    formatPhoneNumber();
  } catch (error) {
    error.value = error;
  } finally {
    loading.value = false;
  }
};

const validateInquiry = () => {
  if (!inquiry.value.type) {
    alert("카테고리를 선택해주세요!");
    return false;
  }

  if (!inquiry.value.title) {
    alert("제목을 입력해주세요!");
    return false;
  }

  if (!inquiry.value.content) {
    alert("내용을 입력해주세요!");
    return false;
  }

  if (!inquiry.value.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inquiry.value.email)) {
    alert("유효한 이메일 주소를 입력해주세요!");
    return false;
  }

  if (!inquiry.value.phoneNum || !/^(01[0-9]-\d{4}-\d{4}|\d{11})$/.test(inquiry.value.phoneNum)) {
    alert("유효한 전화번호를 입력해주세요! (예: 010-1234-5678)");
    return false;
  }

  return true; // 모든 조건 통과
};

const saveInquiry = async () => {
  if (!validateInquiry()) {
    return; // 검증 실패 시 종료
  }

  if (confirm("등록 하시겠습니까?")) {
    const formData = new FormData();

    if (selectedFiles.value && selectedFiles.value.length > 0) {
      selectedFiles.value.forEach((file) => {
        formData.append('files', file); // key 이름은 백엔드와 맞춰서 'files'로 설정
      });
    }

    formData.append(
      'reqDto', // key 이름은 백엔드에서 기대하는 이름으로 설정
      new Blob([JSON.stringify(inquiry.value)], { type: 'application/json' })
    );

    try {
      const response = await apiClient.post(API_URL + '/inquiries', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert("등록 되었습니다.");
      router.replace("/support/inquiry");
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadProfile();
});
</script>