<template>
  <!-- s::PAGE -->
  <div class="contents art-artist">
    <!-- s:: ART & ARTIST -->
    <div class="art-artist">
      <div class="list">
        <div class="layout">
          <div class="visual-slide">
            <div class="swiper visualSwiper">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in banners">
                  <template v-if="item.mediaType === 'MP4'">
                    <figure>
                      <video autoplay muted loop playsinline style="width: 100%; height: 100%; object-fit: cover;">
                        <source :src="item.imageUrl" type="video/mp4">
                      </video>
                    </figure>
                  </template>
                  <template v-else>
                    <figure><img :src="item.imageUrl" alt=""></figure>
                  </template>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>

          <div class="filter-box">
            <div class="group-box">
              <SearchBar v-model="searchQuery" placeholder="아티스트 이름" @submit="onSearch"/>

              <!-- 정렬 방식 텍스트 -->
              <div class="sort-options">
                <span class="checkbox-type1">
                  <input type="checkbox" id="searchOnlyLike" v-model="searchOnlyLike" @change="toggleSearchOnlyLike">
                  <label for="searchOnlyLike" style="color: #999; font-size: 20px;">
                    관심 아티스트
                  </label>
                </span>
                <span v-for="(option, index) in ARTIST_LIST_SORT_OPTIONS" :key="index"
                      :class="{ selected: selectedSort.value === option.value }" @click="setSortOption(option)"
                      style="font-size: 20px;">
                  {{ option.label }}
                </span>
              </div>
            </div>

            <!-- <div class="sort-box">  자음검색 기능 MVP 에서 일단 제외 추후 적용. 
              <a v-for="(filter, index) in filters" :key="index" href="#" :class="{ on: selectedIndex === index }"
                @click.prevent="selectFilter(index)">
                {{ filter }}
              </a>
            </div> -->
          </div>

          <ul v-if="artists && artists.length > 0" class="ret-list">
            <li v-for="(artist, index) in artists" :key="artist.uuid">
              <router-link :to="{ name: 'ArtistDetail', params: { uuid: artist.uuid } }">
                <div class="txt">
                  <h3>{{ artist.nameEn }}</h3>
                  <p>{{ artist.nameKo }}</p>
                </div>
                <div class="img-box">
                  <figure>  <!--  class="arch-image"-->
                    <img :src="artist.imageUrl" alt=""/>
                  </figure>
                </div>
              </router-link>
              <!-- <button type="button" class="btn-like" :class="{ on: artist.isLike }"
                @click="toggleLike($event, artist.uuid, index)">좋아요</button> -->

              <button type="button" class="btn-heart" :class="{ on: artist.isLike }"
                      @click="toggleLike($event, artist.uuid, index)">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                  <path
                      d="M16.6178 3.0062C16.2066 2.56398 15.7184 2.21319 15.181 1.97385C14.6436 1.73452 14.0676 1.61133 13.4859 1.61133C12.9042 1.61133 12.3282 1.73452 11.7908 1.97385C11.2534 2.21319 10.7652 2.56398 10.354 3.0062L9.50054 3.92352L8.6471 3.0062C7.81646 2.11337 6.68987 1.61179 5.51517 1.61179C4.34046 1.61179 3.21387 2.11337 2.38323 3.0062C1.55259 3.89902 1.08594 5.10995 1.08594 6.37259C1.08594 7.63524 1.55259 8.84617 2.38323 9.73899L3.23666 10.6563L9.50054 17.3891L15.7644 10.6563L16.6178 9.73899C17.0293 9.29698 17.3556 8.77218 17.5783 8.19456C17.801 7.61695 17.9156 6.99783 17.9156 6.37259C17.9156 5.74736 17.801 5.12824 17.5783 4.55063C17.3556 3.97301 17.0293 3.44821 16.6178 3.0062Z"
                      stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>

            </li>
          </ul>
          <ul v-else>
            <!-- 검색된 데이터가 없을 경우 -->
            <!-- <li class="nodata">
              검색된 작가 또는 작품명이 없습니다.
            </li> -->
            <div class="empty-wrap">
              <img src="@/assets/images/icon/ico_search_g.svg" alt="">
              <p>검색된 내용이 없습니다.<br>다른 검색어를 입력해주세요.</p>
            </div>
          </ul>

          <Pagination :current-page="currentPage" :total-pages="totalPages" :visible-count="5" @goToPage="goToPage"/>
          <InfiniteScroll :on-reach-end="goToPage" :current-page="currentPage" :total-pages="totalPages"
                          :is-fetching="isFetching"/>

        </div>
      </div>
    </div>
    <!-- e:: ART & ARTIST -->
  </div>
  <!-- e::PAGE -->
</template>
<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import Swiper from "swiper";
import {useAuthStore} from "@/store/auth.module";
import Pagination from "@/components/ui/Pagination.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll.vue";
import {ARTIST_LIST_SORT_OPTIONS} from "@/common/common-enum";
import SearchBar from "@/components/ui/SearchBar.vue";

const loading = ref(true);
const authStore = useAuthStore();
const artists = ref(null);
const banners = ref([]);
const mainSwiper = ref();
const selectedSort = ref(ARTIST_LIST_SORT_OPTIONS[0]);
const searchOnlyLike = ref(false);

const searchQuery = ref(''); // 실제 검색 값
const selectedIndex = ref(0); // 현재 선택된 인덱스

const filters = ['전체', 'ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];
const selectFilter = (index) => { // 자음 검색 기능
  selectedIndex.value = index; // 선택된 인덱스 변경
  searchQuery.value = index === 0 ? '' : filters[index]; // '전체' 선택 시 빈 문자열, 나머지는 그대로
  //관심 선택 해제해야하면 searchOnlyLike.value = false;
  //param 하나더 넘겨서 초성검색(성만) 인거 구분해야할듯.
  setSortOption(ARTIST_LIST_SORT_OPTIONS[1])
};

// 페이지 정보
const currentPage = ref(0);
const size = ref(24);
const totalPages = ref(0);
const isFetching = ref(false);

const setSortOption = (sort) => {
  if (selectedSort.value.value != sort.value) {
    selectedSort.value = sort;
    fetchArtists(0); // 정렬 방식 변경 시 첫 페이지부터 다시 로딩
  }
};

const toggleSearchOnlyLike = () => {
  if (searchOnlyLike && !authStore.status.loggedIn) {
    searchOnlyLike.value = false;
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  fetchArtists(0); // 관심 아티스트 선택 시 첫 페이지부터 다시 로딩
}

function goToPage(page = 0, append = false) {
  fetchArtists(page, append);
}

function onSearch() {
  currentPage.value = 0;   // 검색 시 첫 페이지부터 다시 로딩
  fetchArtists(0);
}

const toggleLike = async (event, artistUuid, index) => {
  if (!authStore.status.loggedIn) {
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  const button = event.target.closest('button');
  const isLiked = button.classList.contains("on");
  const msg = !isLiked ? "관심 아티스트 등록" : "관심 아티스트 삭제";
  if (confirm(msg + " 하시겠습니까?")) {
    try {
      if (!isLiked) {
        await apiClient.post('/v1/users/me/likes', {
          type: "ARTIST",
          uuid: artistUuid,
        });
      } else {
        await apiClient.delete('/v1/users/me/likes', {
          data: {
            type: "ARTIST",
            uuid: artistUuid,
          },
        });
      }

      artists.value[index].isLike = !isLiked;
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  }
}

const fetchBanners = async () => {
  try {
    const response = await apiClient.get('/v1/contents/banners', {
      params: {type: 'ART_ARTIST'}
    });
    banners.value = response.data;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  }
};

const fetchArtists = async (page = 0, append = false) => {
  if (isFetching.value) {
    return;
  }
  isFetching.value = true;

  try {
    const response = await apiClient.get(
        `/v1/contents/artists?q=${searchQuery.value}&like=${searchOnlyLike.value}&page=${page}&size=${size.value}&sort=${selectedSort.value.value}&direction=${selectedSort.value.direction}`);
    artists.value = response.data.content;
    const newData = response.data.content;

    if (append) {
      artists.value.push(...newData);
    } else {
      artists.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching artist data:", error);
  } finally {
    isFetching.value = false;
    loading.value = false;
  }
};

const setSwiper = () => {
  mainSwiper.value = new Swiper('.visualSwiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: false,
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    pagination: {
      el: ".swiper-pagination"
    },
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
  }).init();
};

const init = async () => {
  setSwiper();
  fetchBanners();
  fetchArtists();
};

onMounted(async () => {
  await init();
});
</script>

<style scoped>
/*  아치 이미지 temp
.arch-image { 
  position: relative;
  width: 100%;
  aspect-ratio: 1/4;
}

.arch-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 100% 70%;
  border-top-right-radius: 100% 70%;
} */

.sort-options {
  display: flex;
  align-items: center;
  gap: 20px;
  /* 옵션 간의 간격 */
}

.sort-options span {
  cursor: pointer;
  font-size: 16px;
  color: #999;
  /* 기본 텍스트 색상 (회색) */
  transition: color 0.3s ease;
}

.sort-options span.selected {
  color: #ffc107;
  /* 선택된 텍스트 색상 (노란색) */
  font-weight: bold;
  /* 선택된 텍스트 강조 */
}
</style>