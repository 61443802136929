<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div class="content-panel">
            <div class="customer inquiry">
              <div class="bbs">
                <section>
                  <div class="title-wrap">
                    <h2 class="section-title">1:1 문의
                      <span class="sub">{{ inquiry.type }}</span>
                    </h2>
                  </div>
                  <div class="bbs-view">
                    <h3>{{ inquiry.title }}
                      <span :class="['status', inquiry.isAnswered ? 'done' : 'ing']">
                        {{ inquiry.isAnswered ? '답변완료' : '답변대기' }}
                      </span>
                    </h3>
                    <div class="util">
                      <span>{{ inquiry.userAccountId }}</span>
                      <span>{{ inquiry.createdAt }}</span>
                    </div>
                    <div class="view-content">
                      <!-- 프리사인된 URL 리스트 출력 -->
                      <div v-if="inquiry.privatePresignedUrls && inquiry.privatePresignedUrls.length"
                        class="image-container">
                        <div v-for="(url, index) in inquiry.privatePresignedUrls" :key="index" class="image-wrapper">
                          <!-- URL로 이미지를 표시 -->
                          <img :src="url" alt="Image" class="inquiry-image" />
                        </div>
                      </div>
                      <p>{{ inquiry.content }}</p>
                    </div>
                    <div class="view-content answer" v-show="inquiry.isAnswered">
                      <div class="util">
                        <span>{{ inquiry.answerAdminAccountId }}</span>
                        <span>{{ inquiry.answeredAt }}</span>
                      </div>
                      <p>{{ inquiry.answer }}</p>
                    </div>
                    <div class="button-box">
                      <a @click="goList" href="#">목록으로</a>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useUnreadStore } from '@/store/unread.module';
import { apiClient } from "@/services/auth-header";
import { INQUIRY_TYPE } from "@/common/common-enum";
import { getLabelFromType } from "@/common/common-function";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
// 상태 변수 선언
const unreadStore = useUnreadStore();
const route = useRoute();
const router = useRouter();
const loading = ref(true);
const error = ref(null);
const inquiryUuid = ref(route.params.uuid);
const API_URL = "/support"
const inquiry = ref({
  type: '',
  title: '',
  userAccountId: '',
  createdAt: '',
  isAnswered: false,
  privatePresignedUrls: [],
  content: '',
  answerAdminAccountId: '',
  answeredAt: '',
  answer: '',
  isRead: true,
});

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const goList = () => {
  const { page, title } = route.query; // page와 title을 쿼리에서 가져옴
  router.push({ name: "Inquiry", query: { page, title } }); // 목록으로 이동 시 쿼리 유지
};

const loadItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/inquiries/${inquiryUuid.value}`);
    inquiry.value = response.data;
    inquiry.value.type = getLabelFromType(INQUIRY_TYPE, inquiry.value.type);
  } catch (error) {
    console.error("Error fetching NoticeDetail data:", error);
  } finally {
    loading.value = false;
    if (inquiry.value.isAnswered && !inquiry.value.isRead) {
      await markAsRead();
    }
    unreadStore.fetchUnreadAnsweredInquiriesCount();
  }
};

const markAsRead = async () => {
  try {
    const response = await apiClient.put(`${API_URL}/inquiries/${inquiryUuid.value}/read`);
  } catch (error) {
    console.error('Error marking as read:', error);
  }
};

onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadItem();
});

</script>

<style scoped>
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin: 20px;
}

.image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
}

.inquiry-image {
  display: block;
  max-width: 100%;
  height: auto;
}
</style>