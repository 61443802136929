<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div class="content-panel">
            <div class="my-artartist">
              <div class="my-artwork">
                <div class="title-wrap">
                  <h2 class="section-title capital">My Artwork</h2>
                  <div v-if="allArtWorks.length > 0" class="title-exp">
                    <p>업로드한 아트워크는 ART & ARTISTS 페이지에 모두 노출됩니다.<br>
                      ‘카테고리 노출 관리’를 클릭하면 카테고리 생성 및 삭제, 아트워크 노출 순서 변경이 가능합니다.</p>
                    <div class="button-box">
                      <button type="button" class="btn-art" id="btnManage" @click="openArtWorkManageModal">
                        <span>아트워크 노출 관리</span>
                        <img src="@/assets/images/icon/ico_right.svg" alt="">
                      </button>
                    </div>
                  </div>
                </div>
                <div class="register">
                  <ul>
                    <li>
                      <button type="button" class="register-artwrork" @click="() => openModal(MODE_REGISTER, null)">
                        <img src="@/assets/images/icon/ico_add_ye.svg" alt="">
                      </button>
                    </li>
                    <li v-for="(artWork, index) in allArtWorks" :key="index">
                      <img v-if="artWork" :src="artWork.imageUrl" :alt="artWork.title" />
                      <div class="mask">
                        <button type="button" class="btn-art-edit" @click="() => openModal(MODE_MODIFY, artWork)">
                          아트워크 정보 수정
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>

  <!-- s::모달창 -->
  <!-- 아트워크 등록 모달 -->
  <div v-if="isModalVisible" class="modal-wrap modal" id="modalArtworkSave">
    <div class="modal-upload">
      <button type="button" class="close-modal" @click="closeModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <form action="" id="artForm">
        <div class="form-box">
          <div class="picture-box">
            <div ref="filebox" id="filebox" class="filebox">
              <label for="upload-img">작품 이미지 업로드</label>
              <input type="file" id="upload-img" accept="image/*" @change="loadImage">
              <img ref="output" v-if="modalArtWork" id="output" :src="modalArtWork.imageUrl">
            </div>
            <p>
              jpg 또는 png 형식으로<br>
              고화질 파일을 업로드해주세요.
            </p>
          </div>
          <div class="field-box">
            <ul>
              <li>
                <div class="title">
                  <span class="req">장르</span>
                </div>
                <div class="col">
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.genre" :options="ARTIST_ACTIVITY_GENRES" @change="handleChange"
                      placeholder="장르 선택" />
                    <div class="field">
                      <input type="text" class="etc" v-if="showEtc" v-model="modalArtWork.etc" placeholder="(직접 입력)">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">작품명</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="sub-title">
                      <span class="req">국문</span>
                    </div>
                    <div class="field field-sep">
                      <input type="text" placeholder="작품 제목을 입력해주세요." required v-model="modalArtWork.title">
                    </div>
                  </div>
                  <div class="row">
                    <div class="sub-title">
                      <span>영문</span>
                    </div>
                    <div class="field field-sep">
                      <input type="text" placeholder="영문 제목을 입력해주세요. 없을 경우 생략 할 수 있습니다." v-model="modalArtWork.titleEn">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">제작 시기</span>
                </div>
                <div class="col">
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.year" :options="selectYearRange" placeholder="연도" />
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">사이즈</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="field">
                      <input type="text" placeholder="가로 X 세로 X 높이 mm" required v-model="modalArtWork.size">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">재료</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="field">
                      <input type="text" placeholder="사용된 재료를 알려주세요." required v-model="modalArtWork.material">
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="title">
                  <span class="req">테마</span>
                </div>
                <div class="col">
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeSubject" :options="ARTIST_ARTWORK_THEME_SUBJECT"
                      placeholder="표현대상" />
                  </div>
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeTime" :options="ARTIST_ARTWORK_THEME_TIME"
                      placeholder="시간" />
                  </div>
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeFocus" :options="ARTIST_ARTWORK_THEME_FOCUS"
                      placeholder="포커스" />
                  </div>
                  <div class="row">
                    <CustomSelect v-model="modalArtWork.themeStyle" :options="ARTIST_ARTWORK_THEME_STYLE"
                      placeholder="방식" />
                  </div>
                </div>
              </li>
              <li class="explaint">
                <div class="title">
                  <span>작품 설명</span>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="field">
                      <textarea name="" id="" v-model="modalArtWork.description"
                        placeholder="아티스트의 창작 의도나 작품을 이해하는 데 도움이 되는 설명이나 키워드를 입력해주세요. 이 정보는 다른 회원에게는 공개되지 않으며, 아티클21에서 해당 아티스트 회원에게 기업이나 브랜드와의 컬래버레이션, 아트상품 제작 등을 제안하는 데 활용할 수 있습니다."></textarea>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div class="button-box">
              <button v-if="modalMode === MODE_MODIFY" type="button" class="cancel" id="removeArtwork"
                @click="openDeleteModal">아트워크 삭제하기</button>
              <button type="button" id="btnArtSave" @click="saveArtWork">아트워크 {{ modalMode === MODE_REGISTER ? "등록" :
                "수정" }}하기</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- 아트워크 삭제 안내 모달 -->
  <div v-if="isDeleteModalVisible" class="modal-wrap-3 modal" id="modalDelArtwork">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="closeDeleteModal">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>아트워크를 삭제할까요?</h4>
        <p>선택하신 아트워크가 ART & ARTISTS 페이지에서 삭제됩니다.</p>
      </div>
      <div class="button-box">
        <button type="button" @click="deleteArtWork">삭제</button>
      </div>
    </div>
  </div>

  <!-- 아트워크 노출 관리 모달 -->
  <div class="modal-wrap2 modal" id="modalManageArtwork" v-if="isManageModalVisible">
    <div class="sort-panel">
      <div class="modal-title-wrap">
        <h2 class="section-title">아트워크 노출 관리</h2>
        <button type="button" class="close-modal" @click="closeArtWorkManageModal">
          <img src="@/assets/images/icon/ico_close_w.svg" alt="">
        </button>
      </div>
      <div class="inner-box">
        <div class="l-cont">
          <div class="cont-wrap">
            <div class="category-wrap">
              <ul class="category">
                <li>
                  <button type="button" id="category-all" :class="['btn-category', { 'active': !selectedArtWorksCategory }]" @click="setCategory(null)">All</button>
                </li>
                <li v-for="(category, index) in artWorksCategories" :key="index">
                  <button type="button" :id="'category-' + index" :class="['btn-category', { 'active': selectedArtWorksCategory && selectedArtWorksCategory.uuid === category.uuid }]" @click="setCategory(category)">
                    <span>{{category.name}}</span>
                  </button>
                </li>
              </ul>
              <div class="btn-wrap">
                <button type="button" class="add-s" id="btnAddCategory" @click="setCategoryAddModalVisible(true)">
                  <span>추가</span>
                  <img src="@/assets/images/icon/ico_add_wh.svg" alt="">
                </button>
                <button type="button" v-if="selectedArtWorksCategory" class="add-s" id="btnEditCategory" @click="setCategoryUpdateModalVisible(true)">
                  <span>수정</span>
                  <img src="@/assets/images/icon/ico_edit.svg" alt="">
                </button>
              </div>
            </div>
            <div class="sort-list-wrap">
              <div class="sort-list-content" id="contentAll">
                <div class="sort-title">
                  <h3>전체 아트워크</h3>
                  <p>우측 상단의 추가+ 버튼을 누르면 카테고리를 3개까지 생성할 수 있습니다.</p>
                  <!-- <p>선택된 카테고리에 넣을 아트워크를 선택해주세요.</p> -->
                </div>
                <ul class="sort-list connected-sortable">
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure><img src="@/assets/images/@draft/@artwork.png" alt=""></figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="sort-list-content" id="contentCategory1" style="display: none;">
                <div class="sort-title">
                  <h3>덩어리들_123 아트워크</h3>
                  <p>우측 상단의 추가+ 버튼을 누르면 카테고리를 3개까지 생성할 수 있습니다.</p>
                  <!-- <p>선택된 카테고리에 넣을 아트워크를 선택해주세요.</p> -->
                </div>
                <ul class="sort-list connected-sortable">
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure><img src="@/assets/images/@draft/@artwork.png" alt=""></figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure><img src="@/assets/images/@draft/artwork02.png" alt=""></figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure><img src="@/assets/images/@draft/artwork03.png" alt=""></figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="sort-bottom">
                <button type="button" class="btn-line btn-insert" disabled>
                  <span>카테고리에 넣기</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="18" viewBox="0 0 8 18" fill="none">
                    <path d="M-8.79878e-07 1.68512L0.780931 0.765626L8 9.26562L0.780933 17.7656L3.36909e-07 16.8461L6.43814 9.26563L-8.79878e-07 1.68512Z" fill="#16151A"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="r-cont">
          <div class="cont-wrap">
            <div class="cont-title">
              <p class="pc">이미지를 드래그하면 노출 순서를 바꿀 수 있습니다.</p>
              <p class="mo">아트워크 선택 후 우측 버튼을 누르면 해당 카테고리에서 제외할 수 있습니다. (중복 선택 가능)</p>
              <button type="button" class="btn-line mobile btn-remove" disabled>
                <span>카테고리에 빼기</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="8" viewBox="0 0 10 5" fill="none">
                  <path d="M0.650645 4.7954L0.123047 4.34731L5.00026 0.205078L9.87748 4.34731L9.34988 4.7954L5.00026 1.10126L0.650645 4.7954Z" fill="#FFF3CE"/>
                </svg>
              </button>
            </div>
            <div class="sort-list-wrap no-bg">
              <div class="sort-list-border">
                <!-- 리스트가 없는 경우 (처음에) sort-list-border에 empty 클래스 추가하면 됩니다. (글자 자동 출력) -->
                <ul class="sort-list2 connected-sortable">
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                  <li>
                    <img class="dots" src="@/assets/images/icon/ico_drag.svg" alt="">
                    <i><svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
                      <path d="M15.7439 1L6.92962 12.4675L1 4.8225" stroke="#151419" stroke-width="1.92857" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg></i>
                    <figure>
                      <img src="@/assets/images/@draft/@artwork.png" alt="">
                    </figure>
                    <div class="mask txt">
                      <p>
                        the garden where they were _yellow
                        Acrylic on canvas _ 91x73x3cm<br>
                        2017
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-box">
        <button type="button">
          <span>설정 완료</span>
          <img src="@/assets/images/icon/ico_right2.svg" alt="">
        </button>
      </div>
    </div>
  </div>

  <!-- 카테고리 추가 모달 -->
  <div class="modal-wrap-4 modal" id="modalAddCategory" v-if="isCategoryAddModalVisible">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="setCategoryAddModalVisible(false)">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>카테고리 만들기</h4>
        <div class="modal-form-box">
          <div class="input-wrap">
            <span>이름</span>
            <input type="text" v-model="inputCategoryName" id="categoryInput" placeholder="카테고리 이름을 설정해주세요." required>
          </div>
          <div class="input-wrap">
            <span class="checkbox-type3">
              <input type="checkbox" id="categoryHide" v-model="inputCategoryHide">
              <label for="categoryHide">
                <span>숨김</span>
                <span class="sub">ART & ARTISTS에 노출하고 싶지 않을 경우 체크해주세요.</span>
              </label>
            </span>
          </div>
        </div>
      </div>
      <div class="button-box">
        <button type="button" id="categorySave" @click="saveCategory">만들기</button>
      </div>
    </div>
  </div>
  <!-- 카테고리 수정 모달 -->
  <div class="modal-wrap-4 modal" id="modalEditCategory" v-if="isCategoryUpdateModalVisible">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="setCategoryUpdateModalVisible(false)">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>카테고리 수정</h4>
        <div class="modal-form-box">
          <div class="input-wrap">
            <span>이름</span>
            <input type="text" v-model="inputCategoryName" placeholder="카테고리 이름을 설정해주세요." value="덩어리들">
          </div>
          <div class="input-wrap">
                    <span class="checkbox-type3">
                        <input type="checkbox" id="categoryHide02" v-model="inputCategoryHide" checked>
                        <label for="categoryHide02">
                            <span>숨김</span>
                            <span class="sub">ART & ARTISTS에 노출하고 싶지 않을 경우 체크해주세요.</span>
                        </label>
                    </span>
          </div>
          <div class="input-wrap">
            <button type="button" id="btnDelCategory" @click="setCategoryDeleteModalVisible(true)">카테고리 삭제</button>
            <p>선택 시 카테고리가 ART & ARTISTS 페이지에서 삭제됩니다.
              <br>카테고리가 삭제돼도 아트워크는 유지됩니다.</p>
          </div>
        </div>
      </div>
      <div class="button-box">
        <button type="button" @click="updateCategory">완료</button>
      </div>
    </div>
  </div>
  <!-- 카테고리 삭제 모달 -->
  <div class="modal-wrap-3 modal del" id="modalDelCategory" v-if="isCategoryDeleteModalVisible">
    <div class="modal-inner">
      <button type="button" class="close-modal" @click="setCategoryDeleteModalVisible(false)">
        <img src="@/assets/images/icon/ico_close_w.svg" alt="">
      </button>
      <div class="modal-content">
        <h4>이 카테고리를 삭제할까요?</h4>
        <p>‘<span id="categoryName">{{selectedArtWorksCategory.name}}</span>’ 카테고리가 ART & ARTISTS 페이지에서 삭제됩니다.
          <br>카테고리가 삭제돼도 아트워크는 마이페이지에서 유지됩니다.
        </p>
      </div>
      <div class="button-box">
        <button type="button" @click="deleteCategory">삭제</button>
      </div>
    </div>
  </div>

  <!-- s::모달창 리스트 관리-->
<!--  <div class="modal-wrap2" v-if="listModalVisible">-->
<!--    <div class="sort-panel">-->
<!--      <div class="sort-options">-->
<!--        <span :class="{ selected: !selectedArtWorksCategory }" @click="setCategory(null)">-->
<!--          ALL-->
<!--        </span>-->
<!--        <span v-for="(category, index) in artWorksCategories" :key="index"-->
<!--          :class="{ selected: selectedArtWorksCategory && selectedArtWorksCategory.uuid === category.uuid }"-->
<!--          @click="setCategory(category)">-->
<!--          {{ category.name }}-->
<!--        </span>-->
<!--      </div>-->
<!--      <button @click="closeModalVisible" type="button" class="close-modalwrap2">-->
<!--        <img src="@/assets/images/icon/ico_close_w.svg" alt="" />-->
<!--      </button>-->
<!--      <div class="inner-box">-->
<!--        <div class="sortable-list left-list l-cont">-->
<!--          <ul ref="leftList" class="sort-list">-->
<!--            <li v-for="(item, index) in leftItems" :key="item.id" :data-id="item.id"-->
<!--              @click="toggleActive(index, item.id)" :class="{ on: activeIndexes.includes(index) }">-->
<!--              <i>{{ clickOrder.indexOf(item.id) !== -1 ? clickOrder.indexOf(item.id) + 1 : '' }}</i>-->
<!--              <img :src="item.imageUrl" alt="" style="width: 176px;height: 176px;" />-->
<!--              <div class="mask">-->
<!--                <p>-->
<!--                  {{ item.title }}_{{ item.theme }}<br />-->
<!--                  {{ item.material }} _ {{ item.size }}<br />-->
<!--                  {{ item.year }}-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--          <div class="btn-box">-->
<!--            <button @click="moveToRightItems" type="button">추가</button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="sortable-list right-list r-cont">-->
<!--          <ul ref="rightList" class="sort-list2">-->
<!--            <li v-for="item in rightItems" :key="item.id" :data-id="item.id">-->
<!--              <figure>-->
<!--                <img :src="item.imageUrl" alt="" style="width: 58px;height: 58px;" />-->
<!--              </figure>-->
<!--              <div class="txt">-->
<!--                <p>-->
<!--                  {{ item.title }}_{{ item.theme }}<br />-->
<!--                  {{ item.material }}<br />{{ item.size }}-->
<!--                  {{ item.year }}-->
<!--                </p>-->
<!--              </div>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="button-box">-->
<!--        <button @click="updateArtworks" type="button">아트워크 게시하기</button>-->
<!--      </div>-->
<!--    </div>-->

<!--  </div>-->
  <!-- e::모달창 리스트 관리-->

  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import 'swiper/swiper-bundle.css';
import { apiClient } from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import $ from "jquery";
import { getSelectYearRange } from "@/common/common-function";
import {
  ARTIST_ACTIVITY_GENRES,
  ARTIST_ARTWORK_THEME_FOCUS,
  ARTIST_ARTWORK_THEME_STYLE,
  ARTIST_ARTWORK_THEME_SUBJECT,
  ARTIST_ARTWORK_THEME_TIME
} from "@/common/common-enum";
import CustomSelect from '@/components/ui/CustomSelect.vue';



const MODE_REGISTER = 'register';
const MODE_MODIFY = 'modify';

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const modalMode = ref(null);

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const selectYearRange = getSelectYearRange();
const artist = ref(null);
const allArtWorks = ref([]);
const artWorksCategories = ref(null);
const selectedArtWorksCategory = ref(null);
const modalArtWork = ref(null);
const emit = defineEmits(['mode-change']);
const rightItems = ref([]);
const leftItems = ref([]);
const rightList = ref(null);
const leftList = ref(null);
const activeIndexes = ref([]);
const clickOrder = ref([]);

const selectedImageFile = ref(null);

const output = ref(null);
const filebox = ref(null);
const isModalVisible = ref(false);
const isDeleteModalVisible = ref(false);
const isManageModalVisible = ref(false);
const isCategoryAddModalVisible = ref(false);
const isCategoryUpdateModalVisible = ref(false);
const isCategoryDeleteModalVisible = ref(false);

const inputCategoryName = ref(null);
const inputCategoryHide = ref(false);

// 데이터 로드 및 초기화
const fetchArtWorks = async () => {

  loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.

  try {
    const response = await apiClient.get('/v1/artists/me/artworks');
    allArtWorks.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const fetchArtWorksCategories = async () => {
  try {
    const response = await apiClient.get(`/v1/artists/me/artworks/categories`);
    artWorksCategories.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const setCategory = (category) => {
  if (category) {
    if (!selectedArtWorksCategory.value || selectedArtWorksCategory.value.uuid !== category.uuid) {
      selectedArtWorksCategory.value = category;
    }
  } else {
    if (selectedArtWorksCategory.value) {
      selectedArtWorksCategory.value = null;
    }
  }
};

const saveCategory = async () => {
  try {
    if (!inputCategoryName.value) {
      alert("카테고리 이름을 입력해주세요.");
      return;
    }

    if (artWorksCategories.value.length >= 3) {
      alert("생성 가능한 카테고리 갯수를 초과했습니다. (3개까지 생성 가능)");
      return;
    }

    await apiClient.post(`/v1/artists/me/artworks/categories`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();

    setCategoryAddModalVisible(false);
  } catch (error) {
    alert("카테고리 생성에 실패했습니다.");
  }
};

const updateCategory = async () => {
  try {
    if (!inputCategoryName.value) {
      alert("카테고리 이름을 입력해주세요.");
      return;
    }

    await apiClient.put(`/v1/artists/me/artworks/categories/${selectedArtWorksCategory.value.uuid}`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();

    // 업데이트된 목록에서 선택된 카테고리를 찾아서 반영
    const updatedCategory = artWorksCategories.value.find(
        category => category.uuid === selectedArtWorksCategory.value.uuid
    );
    if (updatedCategory) {
      selectedArtWorksCategory.value = updatedCategory;
    }

    setCategoryUpdateModalVisible(false);
  } catch (error) {
    alert("카테고리 수정에 실패했습니다.");
  }
}

const deleteCategory = async () => {
  try {
    await apiClient.delete(`/v1/artists/me/artworks/categories/${selectedArtWorksCategory.value.uuid}`, {
      name: inputCategoryName.value,
      isHidden: inputCategoryHide.value,
    });

    await fetchArtWorksCategories();
    setCategory(null);

    setCategoryDeleteModalVisible(false);
    setCategoryUpdateModalVisible(false);
  } catch (error) {
    alert("카테고리 삭제에 실패했습니다.");
  }
}

const loadImage = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedImageFile.value = file;
    output.value.src = URL.createObjectURL(file);
    output.value.onload = () => {
      URL.revokeObjectURL(output.value.src);
      filebox.value.classList.add('on');
    };
  }
};

const openModal = (mode, artWork) => {
  if (artWork) {
    modalArtWork.value = artWork;
  } else {
    modalArtWork.value = {
      uuid: null,
      imageUrl: null,
      title: null,
      titleEn: null,
      year: '',
      size: null,
      material: null,
      genre: null,
      etc: null,
      themeSubject: '',
      themeTime: '',
      themeFocus: '',
      themeStyle: '',
      description: null,
    };
    selectedImageFile.value = null;
  }

  modalMode.value = mode;
  isModalVisible.value = true;
}

const closeModal = () => {
  isModalVisible.value = false;
}

const openDeleteModal = () => {
  isDeleteModalVisible.value = true;
};

const closeDeleteModal = () => {
  isDeleteModalVisible.value = false;
};

const deleteArtWork = async () => {
  try {
    const response = await apiClient.delete(`/v1/artists/me/artworks/${modalArtWork.value.uuid}`);
    if (response.status === 200) {
      alert("아트워크가 삭제되었습니다.");
      // 삭제 후 모달 닫기 및 페이지 갱신 또는 상태 업데이트
      closeDeleteModal();
      isModalVisible.value = false;
      location.reload();
    }
  } catch (error) {
    console.error("Error deleting artwork:", error);
    alert("아트워크 삭제 중 오류가 발생했습니다.");
  }
};

const selectGenre = (selectedValue) => {
  modalArtWork.value.genre = selectedValue;
  if (selectedValue !== 'ETC') {
    modalArtWork.value.etc = '';
  }
};

const saveArtWork = async () => {
  if (!validateArtWorkSave()) {
    return;
  }

  const formData = new FormData();
  if (selectedImageFile.value) {
    formData.append('image', selectedImageFile.value);
  }
  formData.append('reqDto', new Blob([JSON.stringify(modalArtWork.value)], { type: 'application/json' }));
  try {
    let response;
    if (modalMode.value === MODE_REGISTER) {
      response = await apiClient.post('/v1/artists/me/artworks', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      response = await apiClient.put(`/v1/artists/me/artworks/${modalArtWork.value.uuid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    if (response.status === 200) {
      const str = modalMode.value === MODE_REGISTER ? "저장" : "수정";
      alert(str + " 되었습니다.")
    }
    isModalVisible.value = false;
    location.reload();
  } catch (error) {
    console.error('Error saving artwork:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const validateArtWorkSave = () => {
  if (!modalArtWork.value.imageUrl && !selectedImageFile.value) {
    alert("이미지는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.genre === null || modalArtWork.value.genre === '') {
    alert("장르는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.title === null || modalArtWork.value.title === '') {
    alert("작품명 국문은 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.year === null || modalArtWork.value.year === '') {
    alert("제작 시기는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.size === null || modalArtWork.value.size === '') {
    alert("사이즈는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.material === null || modalArtWork.value.material === '') {
    alert("재료는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeSubject === null || modalArtWork.value.themeSubject === '') {
    alert("테마 - 표현대상은 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeTime === null || modalArtWork.value.themeTime === '') {
    alert("테마 - 시간은 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeFocus === null || modalArtWork.value.themeFocus === '') {
    alert("테마 - 포커스는 필수 입니다.")
    return false;
  }

  if (modalArtWork.value.themeStyle === null || modalArtWork.value.themeStyle === '') {
    alert("테마 - 방식은 필수 입니다.")
    return false;
  }

  return true;
}

const openArtWorkManageModal = () => {
  isManageModalVisible.value = true;
};

const closeArtWorkManageModal = () => {
  emit('mode-change', "none");
  isManageModalVisible.value = false;
};

const setCategoryAddModalVisible = (isVisible) => {
  inputCategoryName.value = null;
  inputCategoryHide.value = false;

  isCategoryAddModalVisible.value = isVisible
}

const setCategoryUpdateModalVisible = (isVisible) => {
  if (isVisible) {
    inputCategoryName.value = selectedArtWorksCategory.value.name;
    inputCategoryHide.value = selectedArtWorksCategory.value.isHidden;
  } else {
    inputCategoryName.value = null;
    inputCategoryHide.value = false;
  }

  isCategoryUpdateModalVisible.value = isVisible
}

const setCategoryDeleteModalVisible = (isVisible) => {
  isCategoryDeleteModalVisible.value = isVisible
}

// 아트워크 선택 토글 함수
const toggleActive = (index, id) => {
  const i = activeIndexes.value.indexOf(index);
  if (i === -1) {
    activeIndexes.value.push(index);
  } else {
    activeIndexes.value.splice(i, 1);
  }
  const clickIndex = clickOrder.value.indexOf(id);
  if (clickIndex !== -1) {
    clickOrder.value.splice(clickIndex, 1);
  } else {
    clickOrder.value.push(id);
  }
};

// 아트워크 오른쪽으로 이동 함수
const moveToRightItems = () => {
  clickOrder.value.forEach(id => {
    const itemIndex = leftItems.value.findIndex(item => item.id === id);
    if (itemIndex !== -1) {
      rightItems.value.push(leftItems.value[itemIndex]);
      leftItems.value.splice(itemIndex, 1);
    }
  });
  leftItems.value = leftItems.value.map(item => ({ ...item, isPortfolio: false }));
  rightItems.value = rightItems.value.map(item => ({ ...item, isPortfolio: true }));
  clickOrder.value = [];
  activeIndexes.value = [];
};

// 아이템 업데이트 함수
const updateItems = () => {
  const allItems = [...leftItems.value, ...rightItems.value];
  const leftIDs = Array.from($(leftList.value).children()).map(el => $(el).data('id'));
  const rightIDs = Array.from($(rightList.value).children()).map(el => $(el).data('id'));

  leftItems.value = leftIDs.map(id => {
    const item = allItems.find(item => item.id === id);
    if (item) item.isPortfolio = false;
    return item;
  }).filter(item => item !== undefined);

  rightItems.value = rightIDs.map(id => {
    const item = allItems.find(item => item.id === id);
    if (item) item.isPortfolio = true;
    return item;
  }).filter(item => item !== undefined);
};

const updateArtworks = async () => {
  if (confirm("아트워크를 게시 하시겠습니까?")) {
    const artworks = rightItems.value.map(item => item.id);
    try {
      await apiClient.put('/artwork/update/public', artworks);
      alert("아트워크가 게시 되었습니다.");
      closeArtWorkManageModal();
    } catch (error) {
      alert("서버 접속중 오류가 발생했습니다.");
      console.error(error);
    }
  }
};

const initializeSortable = () => {
  $(leftList.value).sortable({
    connectWith: $(rightList.value),
    stop: () => updateItems()
  });

  $(rightList.value).sortable({
    connectWith: $(leftList.value),
    stop: () => updateItems()
  });
};

const destroySortable = () => {
  if (leftList.value && $(leftList.value).data('ui-sortable')) {
    $(leftList.value).sortable('destroy');
  }
  if (rightList.value && $(rightList.value).data('ui-sortable')) {
    $(rightList.value).sortable('destroy');
  }
};

const reload = () => {
  window.location.reload();
};

// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(async () => {
  await fetchArtWorks();
  await fetchArtWorksCategories();
});
onBeforeUnmount(() => {
  destroySortable();
});

watch(isManageModalVisible, (newVal) => {
  if (newVal) {
    setTimeout(async () => {
      try {
        initializeSortable();
      } catch (error) {
        console.error("Error initializing Swiper", error);
      }
    }, 100);
  } else {
    destroySortable();
  }
});

</script>

<style scoped>
.sort-options {
  display: flex;
  align-items: center;
  gap: 20px;
  /* 옵션 간의 간격 */
}

.sort-options span {
  cursor: pointer;
  font-size: 16px;
  color: #999;
  /* 기본 텍스트 색상 (회색) */
  transition: color 0.3s ease;
}

.sort-options span.selected {
  color: #ffc107;
  /* 선택된 텍스트 색상 (노란색) */
  font-weight: bold;
  /* 선택된 텍스트 강조 */
}

/* 퍼블 끝나고 넣기 mypage.css */
.mypage .my-artartist .my-artwork .register > ul li {
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.mypage .my-artartist .my-artwork .register > ul li > img {
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}
</style>