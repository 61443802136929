<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
      <!-- s:: MYPAGE -->
      <div class="mypage">
        <div class="layout">
          <div class="box">
            <div ref="sideRef" class="side-box">
              <div class="snb2">
                <ProfileSnb/>
              </div>
            </div>
            <div class="content-panel">
              <div class="customer">
                <div class="bbs">
                  <section v-if="notice">
                    <div class="title-wrap">
                                        <h2 class="section-title">공지사항</h2>
                                    </div>
                    <div class="bbs-view">
                      <h3>{{ notice.title }}</h3>
                      <div class="util">
                        <span></span>
                        <span>{{ notice.createdBy }}</span>
                        <span>{{ notice.createdAt }}</span>
                      </div>
                      <div class="view-content">
                        <p>{{ notice.content }}</p>
                      </div>
                      <div class="button-box">
                        <a @click="goList" href="#">목록으로</a>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- e:: MYPAGE -->
    </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import {onMounted, ref} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import {apiClient} from "@/services/auth-header";
import {useRouter, useRoute} from "vue-router";
import { useUnreadStore } from '@/store/unread.module';

const unreadStore = useUnreadStore();
const route = useRoute();
const router = useRouter();

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const noticeUuid = ref(route.params.uuid);
const API_URL = "/support"
const notice = ref(null);

const isMobile = ref(false);
const toggleMenu = () => {
  if (isMobile.value) {
    document.body.classList.toggle('on');
  }
};
// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const goList = () => {
  const { page, title } = route.query; // page와 title을 쿼리에서 가져옴
  router.push({ name: "Notice", query: { page, title } }); // 목록으로 이동 시 쿼리 유지
};


const loadItem = async () => {
  try {
    const response = await apiClient.get(`${API_URL}/notices/${noticeUuid.value}`);
    notice.value = response.data;
  } catch (error) {
    console.error("Error fetching NoticeDetail data:", error);
  }finally {
    loading.value = false;
    if(!notice.value.isRead){
      await markAsRead();
    }
    unreadStore.fetchUnreadNoticesCount();
  }
};

const markAsRead = async () => {
  try {
    const response = await apiClient.post(`${API_URL}/notices/${noticeUuid.value}/read`);
  } catch (error) {
    console.error('Error marking as read:', error);
  }
};


onMounted(() => {
  document.body.classList.remove('bg-white');
  checkMobile();
  loadItem();
});
</script>