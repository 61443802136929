<template>
  <div class="pagination-wrap" v-if="totalPages > 0 && !isMobile">
    <ul>
      <li class="arrow">
        <a href="#" @click="emitGoToPage(0)" :disabled="currentPage === 0" class="first"><img
            src="@/assets/images/icon/ico_first.png" alt=""></a>
        <a href="#" @click="emitGoToPage(currentPage - 1)" :disabled="currentPage === 0" class="prev"><img
            src="@/assets/images/icon/ico_prev.svg" alt=""></a>
      </li>

      <li  v-for="pageIndex in pageNumbers" :key="pageIndex" :class="{ active: pageIndex === currentPage }"
      @click="emitGoToPage(pageIndex)"><a href="#">{{ pageIndex + 1 }}</a></li>

      <li class="arrow">
        <a href="#" @click="emitGoToPage(currentPage + 1)" :disabled="currentPage === totalPages - 1" class="next"><img src="@/assets/images/icon/ico_next.svg" alt=""></a>
        <a href="#" @click="emitGoToPage(totalPages - 1)" :disabled="currentPage === totalPages - 1" class="last"><img src="@/assets/images/icon/ico_last.png" alt=""></a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useDeviceStore } from "@/store/deviceStore";
const deviceStore = useDeviceStore();
const isMobile = computed(() => deviceStore.isMobile);

/**
 * 부모로부터 받을 props 정의
 */
const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
  visibleCount: {
    type: Number,
    default: 5, // 한 번에 보여줄 페이지 수
  },
});

/**
 * 상위로 이벤트를 보낼 emit
 */
const emits = defineEmits(["goToPage"]);

/**
 * 페이지 번호 계산 로직
 */
const pageNumbers = computed(() => {
  const start = Math.max(0, props.currentPage - Math.floor(props.visibleCount / 2));
  const end = Math.min(props.totalPages - 1, start + props.visibleCount - 1);

  const adjustedStart = Math.max(0, end - props.visibleCount + 1);

  const pages = [];
  for (let i = adjustedStart; i <= end; i++) {
    pages.push(i);
  }
  return pages;
});

/**
 * 선택한 페이지를 부모 컴포넌트로 emit
 */
function emitGoToPage(pageIndex) {
  if (pageIndex < 0 || pageIndex >= props.totalPages) return;
  emits("goToPage", pageIndex);
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  gap: 4px;
  margin-top: 16px;
}

.pagination button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 4px;
  color: #fff;

}

.pagination button.active {
  color: #FFD353;
}
</style>