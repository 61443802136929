import { defineStore } from "pinia";
import { ref, onMounted, onUnmounted } from "vue";

export const useDeviceStore = defineStore("device", () => {
  const isMobile = ref(window.innerWidth <= 768);

  onMounted(() => {
    isMobile.value = window.innerWidth <= 768;
  });

  return { isMobile };
});