<template>
  <div v-if="isOverlay" class="overlay"></div>
  <div v-if="isLoading" class="spinner-wrap">
    <div class="loading-box">
      <i><img src="@/assets/images/common/ARTICLE21_B.I.svg" alt="" /></i>
      <span class="loader">Loading</span>
    </div>
  </div>
  <header ref="headerRef">
    <div class="layout">
      <router-link to="/" class="logo"><img src="@/assets/images/common/ARTICLE21_B.I.svg" alt="" /></router-link>
      <div class="h-cont" ref="hContRef">
        <div class="gnb pc">
          <!-- 사용자 메뉴 -->
          <ul>
            <li>
              <router-link to="/artist" :class="{ on: $route.path.startsWith('/artist') }" @click="toggleMenu">
                ART & ARTISTS
              </router-link>
            </li>
            <li>
              <router-link to="/article" :class="{ on: $route.path.startsWith('/article') }" @click="toggleMenu">
                ARTICLE
              </router-link>
            </li>
          </ul>
        </div>
        <div class="gnb mo">
          <ul>
            <li>
              <router-link to="/" :class="{ on: $route.path === '/' }" @click="toggleMenu">HOME</router-link>
            </li>
            <li>
              <router-link to="/artist" :class="{ on: $route.path.startsWith('/artist') }" @click="toggleMenu">
                ART & ARTISTS
              </router-link>
            </li>

            <li class="depth" @click="mobileToggleMenu('article')" :class="{ open: activeMenu === 'article' }">
              <a href="#" :class="{ on: $route.path.startsWith('/article') }">
                <span>ARTICLE</span>
                <img src="@/assets/images/icon/ico_select_wh2.svg" alt=""
                  :class="{ 'rotate-180': activeMenu === 'article' }" />
              </a>
              <div class="m-depth" v-show="activeMenu === 'article'">
                <ul class="depth-2">
                  <li :class="{ on: $route.path.startsWith('/article/special') }">
                    <router-link :to="{ name: 'Special' }" @click="updatePath('special')">SPECIAL</router-link>
                  </li>
                  <li :class="{ on: $route.path.startsWith('/article/exhibition') }">
                    <router-link :to="{ name: 'Exhibition' }" @click="updatePath('exhibition')">EXHIBITION</router-link>
                  </li>
                  <li :class="{ on: $route.path.startsWith('/article/event') }">
                    <router-link :to="{ name: 'Event' }" @click="updatePath('event')">EVENT</router-link>
                  </li>
                  <li :class="{ on: $route.path.startsWith('/article/news') }">
                    <router-link :to="{ name: 'News' }" @click="updatePath('news')">NEWS</router-link>
                  </li>
                </ul>
              </div>
            </li>

            <li v-if="currentUser" class="depth" @click="mobileToggleMenu('mypage')"
              :class="{ open: activeMenu === 'mypage' }">
              <a href="#" :class="{ on: route.path.startsWith('/my') }">
                <span>MY PAGE</span>
                <img src="@/assets/images/icon/ico_select_wh2.svg" alt=""
                  :class="{ 'rotate-180': activeMenu === 'mypage' }" />
              </a>
              <div class="m-depth" v-show="activeMenu === 'mypage'">
                <ul v-if="isArtist || isArtistPending" class="depth-2">
                  <li>ART & ARTISTS</li>
                  <li @click="handleArtistMenuClick" :class="{ on: route.path.startsWith('/my/artist/profile') }">
                    <router-link v-if="!isArtistPending" :to="{ name: 'MyProfile' }">프로필
                      관리</router-link>
                    <a v-else href="#">프로필 관리</a>
                  </li>
                  <li @click="handleArtistMenuClick" :class="{ on: route.path.startsWith('/my/artist/artwork') }">
                    <router-link v-if="!isArtistPending" :to="{ name: 'ProfileArtwork' }">아트워크
                      관리</router-link>
                    <a v-else href="#">아트워크 관리</a>
                  </li>
                  <li @click="handleArtistMenuClick" :class="{ on: route.path.startsWith('/my/artist/portfolio') }">
                    <router-link v-if="!isArtistPending" :to="{ name: 'Portfolio' }">포트폴리오
                      제작</router-link>
                    <a v-else href="#">포트폴리오 제작</a>
                  </li>
                </ul>
                <ul class="depth-2">
                  <li>MY FAVORITE</li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/favorite/aritst') }">
                    <router-link :to="{ name: 'FavoriteArtist' }">관심 아티스트</router-link>
                  </li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/favorite/artwork') }">
                    <router-link :to="{ name: 'FavoriteArtwork' }">관심 아트워크</router-link>
                  </li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/favorite/article') }">
                    <router-link :to="{ name: 'FavoriteArticle' }">스크랩 아티클</router-link>
                  </li>
                </ul>
                <ul class="depth-2">
                  <li>회원 정보 관리</li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/info') }">
                    <router-link :to="{ name: 'MyPage' }">나의 정보</router-link>
                  </li>
                </ul>
                <ul class="depth-2">
                  <li>고객 센터</li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/support/notice') }">
                    <div class="link-wrapper" :class="{ new: unreadStore.unreadNoticesCount > 0 }">
                      <router-link :to="{ name: 'Notice' }">공지 사항</router-link>
                    </div>
                  </li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/support/faq') }">
                    <router-link :to="{ name: 'FAQ' }">FAQ</router-link>
                  </li>
                  <li @click="toggleMenu" :class="{ on: route.path.startsWith('/my/support/inquiry') }">
                    <div class="link-wrapper" :class="{ new: unreadStore.unreadAnsweredInquiriesCount > 0 }">
                      <router-link :to="{ name: 'Inquiry' }">1:1 문의</router-link>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="util-box">
          <a href="#" class="svg-fill">  <!-- 검색 아이콘-->
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M21.6595 19.9413L15.6494 13.9762C18.5358 10.1443 17.8241 4.69264 13.9887 1.76932C10.1534 -1.15401 4.69686 -0.363919 1.77091 3.46801C-1.15504 7.29993 -0.364246 12.7515 3.47112 15.6354C6.59478 18.0056 10.8651 18.0056 13.9887 15.6354L19.9988 21.64C20.4733 22.1141 21.2245 22.1141 21.6595 21.6795C22.0944 21.245 22.134 20.4549 21.6595 19.9413C21.6595 19.9677 21.6595 19.9677 21.6595 19.9413ZM4.26192 13.1861C1.81045 10.7368 1.81045 6.70737 4.26192 4.2581C6.7134 1.80882 10.7465 1.80882 13.1979 4.2581C15.6494 6.70737 15.6494 10.7368 13.1979 13.1861C10.7465 15.6749 6.7134 15.6749 4.26192 13.1861Z"
                fill="white" />
            </svg>
          </a>
          <div class="util-link">
            <router-link v-if="currentUser" :to="{ name: 'FavoriteArtist' }" class="svg-fill" :class="{ on: route.path.startsWith('/my/favorite') }" @click="toggleMenu" title="관심목록">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M10.9972 21.9922C10.7352 21.9922 10.4806 21.8754 10.3085 21.673L1.79668 12.0042C-0.598894 9.27176 -0.598894 4.82661 1.79668 2.09413C2.9795 0.747346 4.56657 0 6.26593 0C7.96529 0 9.54488 0.739561 10.7352 2.09413L10.9972 2.38995L11.2592 2.09413C12.442 0.747346 14.0291 0 15.7285 0C17.4278 0 18.9775 0.723991 20.1603 2.04742C20.1752 2.06299 20.1902 2.07856 20.1977 2.09413C22.6008 4.82661 22.6008 9.27176 20.1977 12.0042L11.6934 21.6808C11.5137 21.8832 11.2667 22 11.0047 22L10.9972 21.9922ZM6.26593 1.954C5.10557 1.954 4.0051 2.47558 3.18162 3.40977C1.41489 5.41826 1.41489 8.68011 3.18162 10.6886L10.9972 19.5789L18.8128 10.6886C20.5645 8.69568 20.5795 5.45718 18.8427 3.44869C18.8277 3.43312 18.8202 3.42534 18.8053 3.40977C17.9818 2.4678 16.8813 1.954 15.721 1.954C14.5606 1.954 13.4601 2.47558 12.6367 3.40977L11.6859 4.49186C11.3341 4.89667 10.6603 4.89667 10.301 4.49186L9.35023 3.40977C8.52675 2.4678 7.42629 1.954 6.26593 1.954Z"
                  fill="white" />
              </svg>
            </router-link>
            <!-- shopping icon 나중에 적용.
              <a href="#" class="svg-stroke">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path
                  d="M1.95733 8.05233C2.00017 7.43119 2.51653 6.94922 3.13914 6.94922H18.8609C19.4835 6.94922 19.9998 7.43119 20.0427 8.05233L20.8413 19.6331C20.8886 20.3178 20.3459 20.8992 19.6595 20.8992H2.34047C1.65414 20.8992 1.11144 20.3178 1.15866 19.6331L1.95733 8.05233Z"
                  stroke="white" stroke-width="2" />
                <path d="M16.5008 7.05C16.5008 3.70868 14.1615 1 11.2758 1C8.39009 1 6.05078 3.70868 6.05078 7.05"
                  stroke="white" stroke-width="2" />
              </svg>
              <span class="badge">12</span>
            </a> -->
            <router-link v-if="currentUser" to="/my/info" class="svg-fill" :class="{ on: route.path.startsWith('/my') }" @click="toggleMenu" title="마이페이지">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <g clip-path="url(#clip0_772_8385)">
                  <path
                    d="M10.9989 12.4353C13.5495 12.4353 15.6189 10.3659 15.6189 7.81531C15.6189 5.26469 13.5495 3.19531 10.9989 3.19531C8.44828 3.19531 6.37891 5.26469 6.37891 7.81531C6.37891 10.3659 8.44828 12.4353 10.9989 12.4353ZM10.9989 4.78344C12.6695 4.78344 14.0308 6.14469 14.0308 7.81531C14.0308 9.48594 12.6695 10.8472 10.9989 10.8472C9.32828 10.8472 7.96703 9.48594 7.96703 7.81531C7.96703 6.14469 9.32828 4.78344 10.9989 4.78344Z"
                    fill="white" />
                  <path
                    d="M11 22C17.0637 22 22 17.0637 22 11C22 4.93625 17.0637 0 11 0C4.93625 0 0 4.93625 0 11C0 17.0637 4.93625 22 11 22ZM4.87438 18.1294C6.02938 15.8125 8.38062 14.3481 11 14.3481C13.6194 14.3481 15.9775 15.8125 17.1256 18.1294C15.4756 19.5456 13.3375 20.405 11 20.405C8.6625 20.405 6.5175 19.5456 4.87438 18.1294ZM11 1.595C16.1906 1.595 20.405 5.81625 20.405 11C20.405 13.2481 19.6144 15.3175 18.2944 16.9331C16.7956 14.3619 14.0525 12.7531 11 12.7531C7.9475 12.7531 5.20437 14.3619 3.70562 16.9331C2.38562 15.3106 1.595 13.2481 1.595 11C1.595 5.80937 5.81625 1.595 11 1.595Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_772_8385">
                    <rect width="22" height="22" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span v-if="
                unreadStore.unreadAnsweredInquiriesCount > 0 ||
                unreadStore.unreadNoticesCount > 0
              " class="badge">N</span>
            </router-link>

            <router-link v-if="!currentUser" to="#" @click="changeMode('login')">Log in</router-link>
            <router-link v-if="!currentUser" to="/auth/signup" @click="toggleMenu">Sign up</router-link>
            <router-link v-if="currentUser" to="#" @click="logOut">Log out</router-link>
          </div>
        </div>
      </div>
      <!-- <button type="button" class="m-search"><img src="@/assets/images/icon/ico_search.svg"
          alt="Mobile Search"></button> -->
      <button type="button" class="nav-icon" ref="navIconRef" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </header>
</template>

<script setup>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useMainStore } from "@/store/pinia";
import { useAuthStore } from "@/store/auth.module";
import { useUnreadStore } from "@/store/unread.module";

const authStore = useAuthStore();
const unreadStore = useUnreadStore();

const props = defineProps({
  showOverlay: Boolean,
});

// 상태 관리 및 참조 변수 초기화
const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();
const isAdmin = ref(false);
const headerRef = ref(null);
const hContRef = ref(null);
const navIconRef = ref(null);
const isOverlay = ref(false);
const isMobile = ref(false);
const isLoading = ref(false);

watch(
  () => props.showOverlay,
  (newVal) => {
    isOverlay.value = newVal;
  }
);

// 토글 메뉴 함수
const handleService = () => {
  alert("서비스 준비 중입니다.");
};

// 토글 메뉴 함수
const toggleMenu = () => {
  if (isMobile.value) {
    navIconRef.value.classList.toggle("open");
    hContRef.value.classList.toggle("active");
    if ($(document.body).hasClass("on")) {
      document.body.classList.remove("on");
    } else {
      document.body.classList.remove("on");
    }
  }
};

const emit = defineEmits(["mode-change"]);
const changeMode = (newMode) => {
  emit("mode-change", newMode);
};

const logOut = () => {
  authStore.logout();
  router.push("/").then(() => {
    window.location.reload();
  });
};

// 모바일 확인 함수
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};

const activeMenu = ref(null); // 활성화된 메뉴를 추적하는 변수

// 메뉴 토글 함수
const mobileToggleMenu = (menu) => {
  activeMenu.value = activeMenu.value === menu ? null : menu;
};

const handleArtistMenuClick = () => {
  if (isArtistPending.value) {
    alert('현재 승인 대기 중이므로 해당 메뉴를 이용할 수 없습니다.');
    return;
  }

  toggleMenu();
};

// 경로 업데이트 함수
const updatePath = (path) => {
  mainStore.setCurrentPath(path);
  toggleMenu();
};

// 컴포넌트 마운트 시 동작 설정
onMounted(() => {
  const isAuthenticated = computed(() => authStore.status.loggedIn);
  if (isAuthenticated.value) {
    const user = authStore.status.user;
    if (user && user.roles && user.roles.length > 0) {
      isAdmin.value = user.roles.includes("ROLE_ADMIN");
    }
  }

  nextTick(() => {
    const header = headerRef.value;
    const headerHeight = header.offsetHeight;

    window.addEventListener("scroll", () => {
      const windowTop = window.scrollY;
      if (windowTop >= headerHeight) {
        header.classList.add("drop");
      } else {
        header.classList.remove("drop");
      }
    });
  });

  checkMobile();

  window.addEventListener("resize", checkMobile);

});

// setup 내부에 상태와 메소드 정의
const currentUser = computed(() => authStore.status.user);

const isArtist = computed(() => authStore.status.user.roleType === "ARTIST");
const isArtistPending = computed(() => authStore.status.user.roleType.startsWith("ARTIST_PENDING"));

</script>

<style scoped>
.tooltip-container {
  position: relative;
  /* 툴팁 위치를 기준으로 함 */
}

.tooltip {
  visibility: hidden;
  /* 기본적으로 툴팁을 숨김 */
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  /* 툴팁을 텍스트 위에 위치시킴 */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
  /* 마우스 오버 시 툴팁을 표시 */
}

/* 임시 */
.depth {
  cursor: pointer;
}

.depth>a>img.rotate-180 {
  transform: rotate(180deg);
}

.m-depth {
  display: none;
  flex-direction: column;
}

.m-depth ul {
  margin: 0;
  padding: 0;
}

.m-depth ul li {
  list-style: none;
}

.depth.open .m-depth {
  display: flex;
}

/* 토글메뉴 스크롤 퍼블이 안해주면,
.gnb.mo .depth .m-depth {
  max-height: 300px; 
  overflow-y: auto;  
} */
</style>
