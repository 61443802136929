<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div class="content-panel">
            <div class="customer">
              <div class="bbs">
                <section>
                  <div class="title-wrap">
                    <h2 class="section-title">공지사항</h2>
                  </div>
                  <div class="bbs-list">
                    <div class="list-top">
                      <p class="total">전체 <em>{{ totalItems }}</em>건</p>
                      <SearchBar v-model="searchQuery" placeholder="검색어" @submit="onSearch" />
                    </div>
                    <ul>
                      <li class="noti-head">
                        <div class="col col1">구분</div>
                        <div class="col col2">제목</div>
                        <div class="col col3">작성자</div>
                        <div class="col col4">등록일</div>
                      </li>
                      <li v-for="item in items" :key="item.uuid" :class="{ noti: item.isPinned }">
                        <div class="col col1">
                          {{ item.isPinned ? '중요' : '일반' }}
                        </div>
                        <div class="col col2">
                          <router-link :to="{
                            name: 'NoticeDetail',
                            params: { uuid: item.uuid },
                            query: { page: currentPage, title: searchQuery }
                          }" :class="{ new: !item.isRead }">
                            <span>{{ item.title }}</span>
                          </router-link>
                        </div>
                        <div class="col col4">{{ item.createdBy }}</div>
                        <div class="col col4">{{ item.createdAt }}</div>
                      </li>
                    </ul>
                  </div>
                  <Pagination :current-page="currentPage" :total-pages="totalPages" :visible-count="5"
                    @goToPage="goToPage" />
                  <InfiniteScroll :on-reach-end="goToPage" :current-page="currentPage" :total-pages="totalPages"
                    :is-fetching="isFetching" />
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute } from "vue-router";
import { useUnreadStore } from '@/store/unread.module';
import { apiClient } from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import Pagination from "@/components/ui/Pagination.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll.vue";
import SearchBar from "@/components/ui/SearchBar.vue";

// 상태 변수 선언
const unreadStore = useUnreadStore();
const route = useRoute();
const loading = ref(true);
const error = ref(null);
const API_URL = "/support"
const items = ref([]);
const totalItems = ref(0);

// 페이지 정보
const searchQuery = ref(route.query.title || ""); // URL의 title 쿼리값 초기화
const currentPage = ref(Number(route.query.page || 0)); // 현재 페이지
const size = ref(10);
const totalPages = ref(0);
const isFetching = ref(false);

function onSearch(query) {
  searchQuery.value = query;
  currentPage.value = 0;
  loadItem(0)
}

function goToPage(page = 0, append = false) {
  loadItem(page,append);
}

const loadItem = async (page = 0, append = false) => {
  if (isFetching.value) return;
  isFetching.value = true;

  try {
    const response = await apiClient.get(`${API_URL}/notices`, {
      params: { title: searchQuery.value, page: page, size: size.value }
    });
    const newData = response.data.content;

    if (append) {
      items.value.push(...newData);
    } else {
      items.value = newData;
    }

    totalItems.value = response.data.totalElements;
    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching Notice data:", error);
  } finally {
    isFetching.value = false;
    loading.value = false;
  }
};

onMounted(() => {
  loadItem(currentPage.value);
  unreadStore.fetchAllUnreadCounts();
});
</script>