import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import { oauthRoutes } from "@/router/oauthRoutes";
import { userRoutes } from "./userRoutes";
import { artistRoutes } from "./artistRoutes";
import { articleRoutes } from "./articleRoutes";
import Policy from "@/components/policy/Policy.vue";
import NotFound from "@/views/NotFound.vue";
import { supportRoutes } from "@/router/supportRoutes";
import { myPageRoutes } from "@/router/myPageRoutes";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  ...userRoutes,
  ...oauthRoutes,
  ...artistRoutes,
  ...articleRoutes,
  ...supportRoutes,
  ...myPageRoutes,
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: Policy,
  },
  {
    path: "/terms",
    name: "TermsPolicy",
    component: Policy,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  const isAuthenticated = !!localStorage.getItem("user"); // 로그인 여부 체크

  if (to.path.startsWith("/my/") && !isAuthenticated) {
    alert("로그인 후 이용하실 수 있습니다.");
    next("/"); 
    return;
  }

  next(); // 정상적인 라우팅 진행
});
// router/index.js
// router.beforeEach((to, from, next) => {
// const isAuthenticated = store.state.auth.status.loggedIn;
// const isAdmin = store.state.auth.user && store.state.auth.user.roles[0] === 'ROLE_ADMIN';
// if(isAuthenticated){
// }
//
// if (to.path.includes('admin') && (!isAuthenticated || !isAdmin)) {
//     next('/');
// } else {
//     next();
// }
// });

export default router;
