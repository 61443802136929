<template>
  <div class="search-box">
    <input type="text" :placeholder="placeholder" v-model="localSearchTerm" @keyup.enter="onSearch" />
    <button type="button" @click="onSearch">
      <img src="@/assets/images/icon/ico_search_b.svg" alt="" />
    </button>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";

/**
 * 부모 컴포넌트에서 넘어오는 props 정의
 * - placeholder: 인풋에 표시할 플레이스홀더
 * - modelValue: v-model로 양방향 바인딩할 검색어
 */
const props = defineProps({
  placeholder: {
    type: String,
    default: "검색어 입력",
  },
  modelValue: {
    type: String,
    default: "",
  },
});

/**
 * 이벤트 정의
 * - update:modelValue: v-model과의 양방향 바인딩을 위해 필요
 * - submit: 실제 검색 버튼 또는 엔터 시 부모에 알릴 때 사용
 */
const emits = defineEmits(["update:modelValue", "submit"]);

/**
 * 로컬 상태로 관리할 검색어
 */
const localSearchTerm = ref(props.modelValue);

/**
 * props로 들어온 modelValue가 바뀌면 localSearchTerm도 반영
 */
watch(
  () => props.modelValue,
  (newValue) => {
    localSearchTerm.value = newValue;
  }
);

/**
 * localSearchTerm이 바뀌면 부모에게 update:modelValue 이벤트로 알려
 */
watch(localSearchTerm, (newValue) => {
  emits("update:modelValue", newValue);
});

/**
 * 검색 함수: 버튼 클릭 또는 엔터 입력 시 실행
 */
function onSearch() {
  emits("submit", localSearchTerm.value);
}
</script>

<style scoped>
/* .search-bar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 16px;
} */

/* 아이콘과 인풋을 함께 배치할 컨테이너 */
/* .input-with-icon {
  position: relative;
  width: 240px;
} */

/* 인풋 스타일 */
/* .search-input {
  width: 100%;
  height: 36px;
  padding: 0 40px 0 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  outline: none;
  background-color: #fff;
} */

/* 아이콘 버튼 스타일 */
/* .icon-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
} */
</style>