<template>
  <li>
    <div class="title">
      <span class="req">경력</span>
    </div>
    <div class="col field style-02">
      <template v-for="(exp, indexExp) in ARTIST_EXPERIENCES" :key="indexExp">
        <div class="row" v-for="(experience, indexExpItem) in groupedExperience[exp.value]" :key="indexExpItem">
          <div class="sub-title">
            <span>{{ exp.label }}</span>
          </div>
          <CustomSelect v-model="experience.year" :options="selectYearRange" placeholder="연도" />
          <div class="field-one input-text-1"><input type="text" v-model="experience.title" maxlength="20"
              placeholder="전시명"></div>
          <div class="field-one input-text-2"><input type="text" v-model="experience.location" maxlength="20"
              placeholder="장소"></div>
          <div class="field-one input-text-3"><input type="text" v-model="experience.region" maxlength="20"
              placeholder="지역"></div>
          <button v-if="indexExpItem === 0" type="button" class="add-list no-text"
            @click="addListItem(experience.type)"><img src="@/assets/images/icon/ico_add3.svg"></button>
          <button v-else type="button" class="add-list no-text delete"
            @click="deleteListItem(experience.type, indexExpItem)">
            <img src="@/assets/images/icon/ico_delete.svg" />
          </button>
        </div>
      </template>
    </div>
  </li>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { ARTIST_EXPERIENCES } from '@/common/common-enum';
import { getSelectYearRange } from "@/common/common-function";
import CustomSelect from '@/components/ui/CustomSelect.vue';

const props = defineProps({
  data: Array,
});

const selectYearRange = getSelectYearRange();

// 내부적으로 다룰 로컬 state
// props.experiences를 직접 수정하지 않고, localExperiences를 조작
const localData = reactive(props.data);

// 모든 타입에 대해 누락된 type이 있다면 초기 아이템 추가
ARTIST_EXPERIENCES.forEach(({ value }) => {
  if (!localData.some((item) => item.type === value)) {
    localData.push({
      type: value,
      year: '',
      title: '',
      location: '',
      region: '',
    });
  }
});

function groupByType(dataArray) {
  return dataArray.reduce((acc, item) => {
    const group = acc[item.type] || [];
    group.push(item);
    acc[item.type] = group;
    return acc;
  }, {});
}

const groupedExperience = computed(() => groupByType(localData));

const addListItem = (type) => {
  localData.push({
    type,
    year: '',
    title: '',
    location: '',
    region: '',
  });
};

const deleteListItem = (type, index) => {
  // 1. type에 해당하는 item들만 찾기
  const itemsOfType = localData.filter((item) => item.type === type);

  // 2. 삭제할 아이템
  const itemToDelete = itemsOfType[index];
  if (itemToDelete) {
    // 3. 전체 localExperiences 배열에서 삭제
    const globalIndex = localData.indexOf(itemToDelete);
    if (globalIndex !== -1) {
      localData.splice(globalIndex, 1);
    }
  }
};

// 사용자가 최종 저장 시 가져갈 데이터
const getFilteredData = () => {
  return localData.filter(({ year, title, location, region }) => {
    return year && title && location && region;
  });
};

const validateData = () => {
  let isAnyFieldFilled = false;

  for (const item of localData) {
    const { year, title, location, region } = item;
    if (year || title || location || region) {
      isAnyFieldFilled = true;
      if (!year || !title || !location || !region) {
        return {
          isValid: false,
          errorMessage:
            '입력 중인 경력이 있습니다. 모든 항목을 입력하거나 사용하지 않으면 삭제하세요.',
        };
      }
    }
  }

  if (!isAnyFieldFilled) {
    return {
      isValid: false,
      errorMessage: '경력이 입력되지 않았습니다. 최소 하나 이상의 경력을 입력해주세요.',
    };
  }

  return {
    isValid: true,
    errorMessage: null,
  };
};

// 외부에서 직접 참조 가능
defineExpose({
  getFilteredData,
  validateData,
});
</script>