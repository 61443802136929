<template><!-- s::PAGE -->

  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb />
            </div>
          </div>
          <div class="content-panel">
            <div class="my-artartist">
              <div class="profile">
                <div class="title-wrap">
                  <h2 class="section-title">프로필</h2>
                </div>
                <form>
                  <div class="register">
                    <div class="picture-box">
                      <div class="img-wrap">
                        <div class="filebox">
                          <label v-if="!previewImageFile.url" for="upload-img">사진 등록</label>
                          <input v-if="!previewImageFile.url" type="file" id="upload-img" accept="image/*"
                            @change="(event) => previewImage(event)">
                          <img v-if="previewImageFile.url" id="output" :src="previewImageFile.url"
                            :alt="previewImageFile.name" />
                        </div>
                        <div class="txt-wrap">
                          <div v-if="previewImageFile.url" class="filebox2"
                            style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin: 5px">
                            <label for="upload-img">이미지 변경</label>
                            <input type="file" id="upload-img" @change="(event) => previewImage(event)"
                              accept="image/*" />
                          </div>
                          <div class="txt-group">
                            <span>사진은 10MB 이하</span>
                            <span>PDF, JPG, PNG 형식으로 등록 가능합니다.</span>
                          </div>
                          <span>사진 크기는 4:3비율로 노출됩니다.</span>
                        </div>
                      </div>

                    </div>
                    <div class="cont-box">
                      <ul>
                        <li>
                          <div class="title">
                            <span class="req">이름</span>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="sub-title">
                                <span>국문</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.nameKo" placeholder="홍길동">
                              </div>
                            </div>
                            <div class="row">
                              <div class="sub-title">
                                <span>영문</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.nameEn" placeholder="GILDONG HONG">
                              </div>
                            </div>
                          </div>
                        </li>
                        <ProfileEducation ref="educationRef" :data="artist.educations" />
                        <ProfileExperience ref="experienceRef" :data="artist.experiences" />


                        <li class="mt">
                          <div class="title">
                            <span class="req">활동 지역</span>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="sub-title">
                                <span>국내</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.domesticArea" placeholder="주요 활동 도시명을 적어주세요.">
                              </div>
                            </div>
                            <div class="row">
                              <div class="sub-title">
                                <span>해외</span>
                              </div>
                              <div class="field">
                                <input type="text" v-model="artist.overseasArea"
                                  placeholder="주요 활동 도시명을 적어주세요. (생략 가능)">
                              </div>
                            </div>
                          </div>
                          <button type="button" class="add-list no-text"><img
                              src="@/assets/images/icon/ico_add3.svg"></button>
                        </li>

                        <li class="colspan">
                          <div class="title">
                            <span class="req">활동 장르<br />(중복 가능)</span>
                          </div>
                          <div class="col">
                            <div class="row">
                              <div class="field">
                                <div class="inner-list">
                                  <div class="li">
                                    <!-- 배포 후에도 칸 밀리면, 여기는 레이아웃이랑 css 수정 해야할듯  -->
                                    <div class="chk-group">
                                      <span v-for="genre in ARTIST_ACTIVITY_GENRES.slice(0, 4)" :key="genre.value"
                                        class="checkbox-type1 chk-one" :class="genre.value === 'ETC' ? '' : ''">
                                        <input type="checkbox" :id="'chk1-' + genre.value" :value="genre.value"
                                          v-model="artist.activityGenreTypes">
                                        <label :for="'chk1-' + genre.value">{{ genre.label }}</label>
                                        <input type="text" class="etc chk-etc-input" placeholder="(직접 입력)"
                                          v-model="artist.activityGenreEtc"
                                          v-if="genre.value === 'ETC' && artist.activityGenreTypes.includes('ETC')">
                                      </span>
                                    </div>
                                    <div class="chk-group">
                                      <span v-for="genre in ARTIST_ACTIVITY_GENRES.slice(4,)" :key="genre.value"
                                        class="checkbox-type1 chk-one" :class="genre.value === 'ETC' ? 'chk-etc' : ''">
                                        <input type="checkbox" :id="'chk1-' + genre.value" :value="genre.value"
                                          v-model="artist.activityGenreTypes">
                                        <label :for="'chk1-' + genre.value">{{ genre.label }}</label>
                                        <input type="text" class="chk-etc-input" placeholder="(직접 입력)"
                                          v-model="artist.activityGenreEtc"
                                          v-if="genre.value === 'ETC' && artist.activityGenreTypes.includes('ETC')">
                                      </span>
                                    </div>
                                    <!-- <span><input type="text" class="etc chk-etc-input" placeholder="(직접 입력)"
                                        v-model="artist.activityGenreEtc"
                                        v-if="artist.activityGenreTypes && artist.activityGenreTypes.includes('ETC')"></span> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <!-- 수상 -->
                        <li class="colspan mt">
                          <ProfileAward ref="awardRef" title="수상 내역" placeholder="수상내역을 적어주세요." :data="artist.awards" />
                        </li>
                        <!-- 작품소장 -->
                        <li class="colspan">
                          <ProfileInput ref="collectionRef" title="작품 소장 내역" placeholder="작품이 소장된 기관 또는 장소가 있다면 적어주세요."
                            :data="artist.collections" />
                        </li>
                        <li class="colspan">
                          <div class="title">
                            <span>아티스트 한 줄 소개</span>
                          </div>
                          <div class="col field style-04">
                            <div class="row">
                              <div class="field-one input-text-1 no-line">
                                <input type="text" maxlength="20" placeholder="프로필에 들어갈 문구입니다. 자유롭게 적어주세요.(20자 이내)"
                                  v-model="artist.introduction">
                              </div>
                            </div>
                          </div>
                        </li>
                        <li class="colspan">
                          <ProfileInput ref="interviewRef" title="인터뷰 링크" placeholder="인터뷰 기사 링크가 있다면 넣어주세요."
                            :data="artist.interviewLinks" />
                        </li>
                        <li class="colspan">
                          <div class="title">
                            <span>동영상 링크</span>
                          </div>
                          <div class="col field style-04">
                            <div class="row">
                              <div class="field-one input-text-1 no-line">
                                <input type="text" placeholder="아티스트나 작품 정보가 담긴 동영상 링크가 있다면 넣어주세요."
                                  v-model="artist.videoLink" @input="validateVideoLink"
                                  :style="{ color: isValidVideoLink ? 'inherit' : 'red' }">
                              </div>
                            </div>
                            <!-- <p v-if="artist.videoLink && !isValidVideoLink" style="color: red;">
                              링크를 확인해주세요.
                            </p> -->
                          </div>
                        </li>

                      </ul>
                      <div class="button-box">
                        <button type="button" @click="reload" style="margin-right: 10px;">취소하기</button>
                        <button type="button" @click="saveProfile">저장하기</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <ImageCropper :imageFile="imageFile" :maxWidth="maxWidth" :maxHeight="maxHeight" :isFixedSize="isFixedSize"
            @cropped="handleCroppedImage" />
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
  <button ref="navIconRef" @click="toggleMenu" type="button" class="mypage-navi">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </button>
</template>


<script setup>
import { onMounted, ref, computed } from 'vue';
import 'swiper/swiper-bundle.css';
import { apiClient } from "@/services/auth-header";
import ProfileSnb from "@/components/snb/MypageSnb.vue";
import ProfileEducation from "@/components/profile/ProfileEducation.vue";
import ProfileInput from "@/components/profile/ProfileInput.vue";
import ProfileExperience from "@/components/profile/ProfileExperience.vue";
import ProfileAward from '@/components/profile/ProfileAward.vue';
import { ARTIST_ACTIVITY_GENRES } from "@/common/common-enum";
import ImageCropper from '@/components/ImageCropper.vue';

// 상태 변수 선언
const loading = ref(true);
const error = ref(null);
const mode = ref("view");

// 토글 메뉴 함수
const navIconRef = ref(null);
const sideRef = ref(null);
const toggleMenu = () => {
  navIconRef.value.classList.toggle('open');
  sideRef.value.classList.toggle('active');
  document.body.classList.toggle('on');
};

const educationRef = ref(null);
const experienceRef = ref(null);
const awardRef = ref(null);
const collectionRef = ref(null);
const interviewRef = ref(null);

const artist = ref({});
const previewImageFile = ref(null);
const selectedImageFile = ref(null);

const reload = () => {
  window.location.reload();
};

const isValidVideoLink = ref(true);

const validateVideoLink = () => {
  try {
    const url = new URL(artist.value.videoLink);
    isValidVideoLink.value = true; // 유효성 검사 로직 추가
  } catch (error) {
    isValidVideoLink.value = false;
  }
};

// 컴포넌트가 마운트되었을 때 데이터 로드
onMounted(() => {
  loadData();
});

// 데이터 로드 및 초기화
const loadData = async () => {
  try {
    const response = await apiClient.get('/v1/artists/me');
    artist.value = response.data;
    previewImageFile.value = { url: artist.value.imageUrl };
    validateVideoLink();
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
  }
};

const saveProfile = async () => {
  const isValid = await validateMandatory();
  if (!isValid) {
    loading.value = false;
    return;
  }

  if (confirm("저장 하시겠습니까?")) {
    loading.value = true; // 작업이 시작되면 로딩 상태를 시작합니다.
    const formData = new FormData();
    if (selectedImageFile.value) {
      formData.append('image', selectedImageFile.value);
    }

    artist.value.educations = educationRef.value.getFilteredData();
    artist.value.experiences = experienceRef.value.getFilteredData();
    artist.value.awards = awardRef.value.getFilteredData();
    artist.value.collections = collectionRef.value.getData();
    artist.value.interviewLinks = interviewRef.value.getData();
    formData.append('reqDto', new Blob([JSON.stringify(artist.value)], { type: 'application/json' }));
    try {
      const response = await apiClient.put('/v1/artists/me', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        alert("저장 되었습니다.");
        location.reload();
      }
    } catch (error) {
      error.value = error; // 에러를 저장합니다.
    } finally {
      loading.value = false; // 작업이 끝난 후 로딩 상태를 끝냅니다.
    }
  }
};

const validateMandatory = async () => {
  if (!artist.value.nameKo && !artist.value.nameEn) {
    alert('작가명을 입력해 주세요.')
    return false;
  }

  if (!educationRef.value.validateData().isValid) {
    alert(educationRef.value.validateData().errorMessage);
    return false;
  }

  if (!experienceRef.value.validateData().isValid) {
    alert(experienceRef.value.validateData().errorMessage);
    return false;
  }

  if (!artist.value.domesticArea && !artist.value.overseasArea) {
    alert('활동지역을 입력해 주세요.');
    return false;
  }

  if (!artist.value.activityGenreTypes || artist.value.activityGenreTypes.length === 0) {
    alert('활동 장르를 선택해 주세요.');
    return false;
  }

  if (!awardRef.value.validateData().isValid) {
    alert(awardRef.value.validateData().errorMessage);
    return false;
  }

  return true;
}

const imageFile = ref(null);
const maxWidth = 193;
const maxHeight = 270;
let isFixedSize = false; // 이미지 크기 고정 여부(고정 하면 maxWidth, maxHeight size 로 파일 생성, 안하면 비율만 유지)

const previewImage = async (event) => {
  const fileInput = event.target; // file input element
  const file = fileInput.files[0];
  if (!file) {
    fileInput.value = "";
    return;
  }
  const maxFileSize = 10 * 1024 * 1024; // 10MB
  if (file.size > maxFileSize) {
    alert("파일 크기가 너무 큽니다. 10MB 이하의 파일만 업로드 가능합니다.");
    return;  // 용량 초과시 함수 종료
  }

  // 부모에서 이미지 파일만 전달
  imageFile.value = file;
  fileInput.value = "";
};

const handleCroppedImage = async (newVal) => {
  const { file, url, name } = newVal;
  previewImageFile.value = { url, name };
  selectedImageFile.value = file;
};

</script>