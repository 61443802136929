<template><!-- s::PAGE -->
  <div class="contents">
    <!-- s:: MYPAGE -->
    <div class="mypage">
      <div class="layout">
        <div class="box">
          <div ref="sideRef" class="side-box">
            <div class="snb2">
              <ProfileSnb/>
            </div>
          </div>
          <div class="content-panel">
            <div class="faq">
              <h2 class="page-title">[TODO]FAQ</h2>
              <div class="box">
                <div class="faq-top">
                  <div class="search-box">
                    <input type="text" placeholder="검색어">
                    <button type="button"><img src="@/assets/images/icon/ico_search_b.svg" alt=""></button>
                  </div>
                  <div class="faq-tab">
                    <div>
                      <a href="#general" class="on">일반 회원</a>
                      <a href="#artist">아티스트 회원</a>
                      <a href="#content">콘텐츠</a>
                      <a href="#artProduct">아트상품</a>
                      <a href="#others">기타</a>
                    </div>
                  </div>
                </div>
                <ul class="head">
                  <li>
                    <div class="col col1">유형</div>
                    <div class="col col2">제목</div>
                  </li>
                </ul>
                <div class="tab-content" id="general">
                  <dl class="toggle-box">
                    <div class="faq-one">
                      <dt>
                        <span>일반 회원</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>일반 회원</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="tab-content" id="artist" style="display: none;">
                  <dl class="toggle-box">
                    <div class="faq-one">
                      <dt>
                        <span>아티스트 회원</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>아티스트 회원</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="tab-content" id="content" style="display: none;">
                  <dl class="toggle-box">
                    <div class="faq-one">
                      <dt>
                        <span>콘텐츠</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>콘텐츠</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="tab-content" id="artProduct" style="display: none;">
                  <dl class="toggle-box">
                    <div class="faq-one">
                      <dt>
                        <span>아트 상품</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>아트 상품</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>아트 상품</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>아트 상품</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="tab-content" id="others" style="display: none;">
                  <dl class="toggle-box">
                    <div class="faq-one">
                      <dt>
                        <span>기타</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>기타</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                    <div class="faq-one">
                      <dt>
                        <span>기타</span>
                        faq 제목
                      </dt>
                      <dd>
                        faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq
                        내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용faq 내용
                      </dd>
                    </div>
                  </dl>
                </div>
                <div class="pagination-wrap">
                  <ul>
                    <li class="arrow">
                      <a href="#" class="first"><img src="@/assets/images/icon/ico_first.png" alt=""></a>
                      <a href="#" class="prev"><img src="@/assets/images/icon/ico_prev.svg" alt=""></a>
                    </li>
                    <li class="active"><a href="#">1</a></li>
                    <li><a href="#">2</a></li>
                    <li><a href="#">3</a></li>
                    <li><a href="#">4</a></li>
                    <li><a href="#">5</a></li>
                    <li class="arrow">
                      <a href="#" class="next"><img src="@/assets/images/icon/ico_next.svg" alt=""></a>
                      <a href="#" class="last"><img src="@/assets/images/icon/ico_last.png" alt=""></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- e:: MYPAGE -->
  </div>
  <!-- e::PAGE -->
</template>


<script setup>
import {onMounted} from 'vue';
import ProfileSnb from "@/components/snb/MypageSnb.vue";

onMounted(() => {
  // 탭 클릭 이벤트
  $('.faq-tab a').on('click', function (e) {
    e.preventDefault(); // 기본 링크 동작 방지

    // 모든 탭 비활성화
    $('.faq-tab a').removeClass('on');
    $(this).addClass('on'); // 클릭한 탭 활성화

    // 모든 탭 콘텐츠 숨기기
    $('.tab-content').hide();

    // 클릭한 탭과 연결된 콘텐츠 표시
    const target = $(this).attr('href'); // a 태그의 href 속성 (id 값)
    $(target).fadeIn(300); // 해당 콘텐츠 표시
  });

  // 초기 상태 설정 (첫 번째 탭 활성화)
  $('.faq-tab a.on').trigger('click'); // 기본적으로 첫 번째 탭 트리거
});
</script>