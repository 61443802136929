<template>
  <!-- s::PAGE -->
  <div class="contents">
    <!-- s:: ART & ARTIST -->
    <div v-if="artist" class="art-artist">
      <div class="view">
        <div class="layout">
          <div class="box">
            <div class="view-panel">
              <div class="title-wrap">
                <h2 class="section-title">Profile</h2>
                <div class="view-btns-wrap">
                  <button type="button" class="btn-heart">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                      <path
                        d="M16.6178 3.0062C16.2066 2.56398 15.7184 2.21319 15.181 1.97385C14.6436 1.73452 14.0676 1.61133 13.4859 1.61133C12.9042 1.61133 12.3282 1.73452 11.7908 1.97385C11.2534 2.21319 10.7652 2.56398 10.354 3.0062L9.50054 3.92352L8.6471 3.0062C7.81646 2.11337 6.68987 1.61179 5.51517 1.61179C4.34046 1.61179 3.21387 2.11337 2.38323 3.0062C1.55259 3.89902 1.08594 5.10995 1.08594 6.37259C1.08594 7.63524 1.55259 8.84617 2.38323 9.73899L3.23666 10.6563L9.50054 17.3891L15.7644 10.6563L16.6178 9.73899C17.0293 9.29698 17.3556 8.77218 17.5783 8.19456C17.801 7.61695 17.9156 6.99783 17.9156 6.37259C17.9156 5.74736 17.801 5.12824 17.5783 4.55063C17.3556 3.97301 17.0293 3.44821 16.6178 3.0062Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </button>
                  <button type="button" class="btn">
                    <span>페이지 공유하기</span>
                    <img src="@/assets/images/icon/ico_share_b2.svg" alt="">
                  </button>
                </div>
              </div>
              <div class="artist-profile">
                <div class="profile-box">
                  <div class="profile">
                    <div class="profile-txt-wrap">
                      <h2>{{ artist.nameEn }}</h2>
                      <h3>{{ artist.nameKo }}</h3>
                    </div>
                    <figure>
                      <img :src="artist.imageUrl" alt="">
                    </figure>
                  </div>
                  <div class="career">
                    <div class="inner-wrap">
                      <button type="button" class="open-career" v-show="!isExpanded" @click="toggleExpand">
                        See More
                      </button>
                      <button type="button" class="close" v-show="isExpanded" @click="toggleExpand"
                        style="display: inline-block;">
                        <img src="@/assets/images/icon/ico_close.png" alt="Close">
                      </button>
                      <div class="career-wrap" :style="{ height: isExpanded ? '880px' : 'fit-content' }">
                        <div class="col1">
                          <h2>{{ artist.introduction }}</h2>
                        </div>
                        <div v-if="allInfo" class="col2">
                          <transition-group name="fade">
                            <dl v-for="[key, value] in visibleItems" :key="key">
                              <dt>{{ key }}</dt>
                              <dd>
                                <ul>
                                  <template v-for="(item, i) in getVisibleDetails(value)" :key="i">
                                    <li v-html="item"></li>
                                  </template>
                                </ul>
                              </dd>
                            </dl>
                          </transition-group>
                        </div>
                      </div>
                    </div>
                    <!-- todo 용도확인 후 연결-->
                    <a href="#" class="btn" v-if="isSpecial">
                      <span>article</span>
                      <img src="@/assets/images/icon/ico_next.svg" alt="">
                    </a>
                  </div>
                </div>
                <div class="banner-box" v-if="isSpecial">
                  <figure><img src="@/assets/images/main/artists2.png" alt=""></figure>
                  <div class="banner-txt-wrap">
                    <p>
                      “감정을 흔들고 시선이 머무르는 그림을 그리고 싶어요. 좋든 나쁘든 내 안에 기억으로 남아있는 공간, 상황, 감정을 떠올리게 하면서 ‘어!’ 하게 되는 작업이랄까요.”
                    </p>
                    <div class="button-box">
                      <a href="#">
                        <span>go to special</span>
                        <img src="@/assets/images/icon/ico_right.svg" alt="">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div ref="viewPanel" class="view-panel" v-if="artWorks">
              <div class="title-wrap style-2">
                <h2 class="section-title">artwork</h2>
                <ul>
                  <li><a :class="{ on: !selectedArtWorksCategory }" @click="setCategory(null)">ALL</a></li>
                  <li v-for="(category, index) in artWorksCategories" :key="index">
                    <a :class="{ on: selectedArtWorksCategory && selectedArtWorksCategory.uuid === category.uuid }" @click="setCategory(category)">{{category.name}}</a>
                  </li>
                </ul>
              </div>

              <div class="artwork-list tab-content">
                <ul class="favorite artwork">
                  <transition-group name="fade">
                    <li v-for="(artWork, index) in artWorks" :key="index">
                      <img :src="artWork.imageUrl" alt="{{ artWork.title }}">
                      <div class="mask">
                        <p v-html="formatArtWorkText(artWork)"></p>
                      </div>
                      <!-- todo : 아트워크 좋아요 기능 구현하기-->
                      <button type="button" class="btn-heart" :class="{ on: artWork.isLike }"
                        @click="artWork.isLike = !artWork.isLike">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                          <path
                            d="M16.6178 3.0062C16.2066 2.56398 15.7184 2.21319 15.181 1.97385C14.6436 1.73452 14.0676 1.61133 13.4859 1.61133C12.9042 1.61133 12.3282 1.73452 11.7908 1.97385C11.2534 2.21319 10.7652 2.56398 10.354 3.0062L9.50054 3.92352L8.6471 3.0062C7.81646 2.11337 6.68987 1.61179 5.51517 1.61179C4.34046 1.61179 3.21387 2.11337 2.38323 3.0062C1.55259 3.89902 1.08594 5.10995 1.08594 6.37259C1.08594 7.63524 1.55259 8.84617 2.38323 9.73899L3.23666 10.6563L9.50054 17.3891L15.7644 10.6563L16.6178 9.73899C17.0293 9.29698 17.3556 8.77218 17.5783 8.19456C17.801 7.61695 17.9156 6.99783 17.9156 6.37259C17.9156 5.74736 17.801 5.12824 17.5783 4.55063C17.3556 3.97301 17.0293 3.44821 16.6178 3.0062Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </button>
                      <a class="btn-share"><img src="@/assets/images/icon/ico_share_w2.svg" alt=""></a>
                      <button type="button" class="btn-more" @click="openArtWorkModal(artWork.uuid)">See More</button>
                    </li>
                  </transition-group>
                </ul>
                <div class="wrap" v-if="totalPages > 1" @click="loadMoreArtWorks">
                  <a class="more">
                    <span>{{ currentPage < totalPages - 1 ? 'See More' : 'Close' }}</span>
                        <img src="@/assets/images/icon/ico_toggle_y.svg" alt=""
                          :style="{ transform: currentPage < totalPages - 1 ? 'rotate(0deg)' : 'rotate(180deg)' }">
                  </a>
                </div>
              </div>
            </div>

            <div class="view-panel" v-if="artist.videoLink">
              <div class="title-wrap">
                <h2 class="section-title">video clip</h2>
              </div>
              <div class="video-box">
                <iframe :src="getEmbedVideoLink(artist.videoLink)" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
            </div>
            <div class="view-panel" v-if="artist.interviews && artist.interviews.length">
              <div class="title-wrap">
                <h2 class="section-title">interview</h2>
              </div>
              <div class="interview-wrap">
                <ul>
                  <li v-for="(interview, index) in artist.interviews" :key="index">
                    <a v-if="interview.link" :href="interview.link" target="_blank" class="title">
                      <div class="txt-wrap">
                        <span class="num">{{ index + 1 }}</span>
                        <span class="title"> {{ interview.title ? interview.title : interview.link }}</span>
                        <!-- todo: new 기능능 <span class="title new"> {{ interview.title ? interview.title : interview.link }}</span> -->
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- <div v-if="isProfileModalOpen && allInfo" class="modal-career">
                  <button type="button" class="close-career" @click="closeProfileModal"><img
                      src="@/assets/images/icon/ico_close_w.svg" alt=""></button>
                  <dl v-for="[key, value] in Object.entries(allInfo)">
                    <dt>{{ key }}</dt>
                    <dd>
                      <ul>
                        <li v-for="item in value">
                          {{ item }}
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- e::PAGE -->
  <div v-show="isArtWorkModalOpen" class="modal-popup modal-artwork">
    <div class="modal-btn-wrap view">
      <button type="button" class="btn-heart">
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="25" viewBox="0 0 27 25" fill="none">
          <path
            d="M23.6494 3.03337C23.0629 2.38873 22.3667 1.87736 21.6003 1.52847C20.834 1.17958 20.0126 1 19.1831 1C18.3535 1 17.5321 1.17958 16.7658 1.52847C15.9994 1.87736 15.3032 2.38873 14.7167 3.03337L13.4997 4.37059L12.2826 3.03337C11.0981 1.73186 9.49152 1.00067 7.81633 1.00067C6.14114 1.00067 4.53455 1.73186 3.35001 3.03337C2.16547 4.33488 1.5 6.10011 1.5 7.94072C1.5 9.78133 2.16547 11.5466 3.35001 12.8481L4.56705 14.1853L13.4997 24L22.4323 14.1853L23.6494 12.8481C24.2361 12.2037 24.7015 11.4387 25.019 10.5967C25.3366 9.75467 25.5 8.85216 25.5 7.94072C25.5 7.02928 25.3366 6.12677 25.019 5.28475C24.7015 4.44273 24.2361 3.6777 23.6494 3.03337Z"
            stroke="#151419" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </button>
      <button type="button" class="btn-share">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.6 6.79902C21.9257 6.79902 23.0001 5.72451 23.0001 4.39951C23.0001 3.0745 21.9257 2 20.6 2C19.2743 2 18.1999 3.0745 18.1999 4.39951C18.1999 5.72451 19.2743 6.79902 20.6 6.79902ZM20.6 8.29902C22.754 8.29902 24.5001 6.55315 24.5001 4.39951C24.5001 2.24587 22.754 0.5 20.6 0.5C18.446 0.5 16.6999 2.24587 16.6999 4.39951C16.6999 4.63265 16.7204 4.86101 16.7596 5.08289L7.68899 10.2359C6.99628 9.06835 5.78208 8.29422 4.39996 8.29422C2.246 8.29422 0.499878 10.1744 0.499878 12.4937C0.499878 14.813 2.246 16.6932 4.39996 16.6932C5.73864 16.6932 6.91979 15.9669 7.62214 14.8604L16.74 20.0402C16.7137 20.2232 16.7001 20.4103 16.7001 20.6005C16.7001 22.7541 18.4462 24.5 20.6002 24.5C22.7541 24.5 24.5002 22.7541 24.5002 20.6005C24.5002 18.4469 22.7541 16.701 20.6002 16.701C19.1758 16.701 17.9298 17.4644 17.2491 18.6043L8.19602 13.4613C8.26404 13.1506 8.30004 12.8267 8.30004 12.4937C8.30004 12.2075 8.27345 11.9279 8.22278 11.6578L17.3104 6.49514C18.003 7.57979 19.2175 8.29902 20.6 8.29902ZM20.6002 23C21.9259 23 23.0002 21.9255 23.0002 20.6005C23.0002 19.2755 21.9259 18.201 20.6002 18.201C19.2744 18.201 18.2001 19.2755 18.2001 20.6005C18.2001 21.9255 19.2744 23 20.6002 23ZM6.80004 12.4937C6.80004 14.091 5.62294 15.1932 4.39996 15.1932C3.17698 15.1932 1.99988 14.091 1.99988 12.4937C1.99988 10.8964 3.17698 9.79422 4.39996 9.79422C5.62294 9.79422 6.80004 10.8964 6.80004 12.4937Z"
            fill="#151419" />
        </svg>
      </button>
      <button type="button" class="close-modal close-artwork" @click="closeArtWorkModal"><img
          src="@/assets/images/icon/ico_close_b.svg" alt=""></button>
    </div>

    <div class="modal-con">
      <div class="swiper-container slider">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(artWork, index) in artWorks" :key="index" :data-id="artWork.uuid">
            <div class="inner">
              <div class="img zoom-area">
                <img @click="toggleArtWorkEnlarge" :class="{ enlarged: isArtWorkEnlarged }" :src="artWork.imageUrl"
                  :alt="artWork.title" />
              </div>
              <div class="txt-box">
                <p v-html="formatArtWorkText(artWork, true)"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-control">
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>

      <div class="swiper-container slider-thumbnail">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(artWork, index) in artWorks" :key="index">
            <img :src="artWork.imageUrl" :alt="artWork.title" />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { apiClient } from '@/services/auth-header';
import { useRoute, useRouter } from "vue-router";
import { ARTIST_ACTIVITY_GENRES, ARTIST_EDUCATIONS, ARTIST_EDUCATIONS_STATUS } from "@/common/common-enum";
import Swiper from "swiper";
// import { URL } from 'url';

const route = useRoute();
const router = useRouter();


const artistUuid = ref(route.params.uuid);
const artist = ref(null);
const artWorks = ref([]);
const artWorksCategories = ref(null);
const artWorkMainSwiper = ref(null);
const artWorkThumbnailSwiper = ref(null);

const selectedArtWorksCategory = ref(null);

// 페이지 정보
const currentPage = ref(0);
// const size = ref(9);
const size = computed(() => (window.innerWidth <= 768 ? 8 : 9));
const totalPages = ref(0);
const groupedExperiences = ref({})
const allInfo = ref({})
const viewPanel = ref(null);

// const isProfileModalOpen = ref(false);
const isExpanded = ref(false); // Profile "See More" 버튼 상태 (기본 false)
const isSpecial = ref(false); // special 링크 있는 경우 
const isArtWorkModalOpen = ref(false);
const isArtWorkEnlarged = ref(false);


const artistEducationsMap = ARTIST_EDUCATIONS.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const artistEducationsStatusMap = ARTIST_EDUCATIONS_STATUS.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

const artistActivityGenresMap = ARTIST_ACTIVITY_GENRES.reduce((map, item) => {
  map[item.value] = item;
  return map;
}, {});

// 전체에서 maxVisibleItems 개수만큼 보여줌
const visibleItems = computed(() => (isExpanded.value ? Object.entries(allInfo.value) : Object.entries(allInfo.value).slice(0, 3)));

// 각 항목에서 maxVisibleDetails 개수만큼 보여줌
const getVisibleDetails = (details) => (isExpanded.value ? details : details.slice(0, 3));

// "See More" 버튼 클릭 핸들러
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

// const openProfileModal = () => {
//   isProfileModalOpen.value = true;
// };

// const closeProfileModal = () => {
//   isProfileModalOpen.value = false;
// };

const formatArtWorkText = (artWork, detail) => {
  // 기본적으로 제목과 연도를 출력
  let formattedText = `${artWork.title}`;

  // detail이 true일 경우, 중간에 material과 size 추가
  if (detail) {
    if (artWork.titleEn) {
      formattedText += ` | ${artWork.titleEn}`; // 재료 추가
    }
    if (artWork.material) {
      formattedText += `<br>${artWork.material}`; // 재료 추가
    }
    if (artWork.size) {
      formattedText += ` | ${artWork.size}`; // 크기 추가
    }
  }

  // 마지막에 연도를 추가
  formattedText += `<br>${artWork.year}`;

  return formattedText;
};

const openArtWorkModal = (uuid) => {

  isArtWorkModalOpen.value = true;
  setTimeout(() => {
    if (artWorkMainSwiper.value) {
      const swiperInstance = artWorkMainSwiper.value;
      const slideElements = swiperInstance.slides; // Swiper의 슬라이드 요소들
      let slideIndex = -1;
      for (let i = 0; i < slideElements.length; i++) {
        if (slideElements[i].getAttribute('data-id') === uuid) {
          slideIndex = i;
          break;
        }
      }

      if (slideIndex !== -1) {
        swiperInstance.slideTo(slideIndex);
      } else {
        console.warn(`Slide with id ${uuid} not found`);
      }
    }
  }, 100);
};

const closeArtWorkModal = () => {
  isArtWorkModalOpen.value = false;
  router.replace({ query: { ...route.query, artwork: undefined } });
};

const toggleArtWorkEnlarge = () => {
  isArtWorkEnlarged.value = !isArtWorkEnlarged.value;
};

const initializeSwiper = () => {
  artWorkThumbnailSwiper.value = new Swiper('.slider-thumbnail', {
    slidesPerView: 3,
    freeMode: true,
    grabCursor: true,
    spaceBetween: 10,
    breakpoints: {
      // when window width is >= 320px
      769: {
        slidesPerView: 4,
        spaceBetween: 10
      },
      // when window width is >= 480px
      1025: {
        slidesPerView: 5,
        spaceBetween: 20
      },
      // when window width is >= 640px
      1281: {
        slidesPerView: 6,
        spaceBetween: 20
      }
    }
  });

  artWorkMainSwiper.value = new Swiper('.slider', {
    grabCursor: true, // 드래그할 때 손 모양의 커서 표시
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    thumbs: {
      swiper: artWorkThumbnailSwiper.value
    }
  });
};

watch(isArtWorkModalOpen, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      initializeSwiper();
    }, 10);
  } else {
    if (artWorkMainSwiper.value) {
      artWorkMainSwiper.value.destroy(true, true);
      artWorkMainSwiper.value = null;
    }
    if (artWorkThumbnailSwiper.value) {
      artWorkThumbnailSwiper.value.destroy(true, true);
      artWorkThumbnailSwiper.value = null;
    }
  }
});

const setAllInfo = () => {
  if (artist.value.activityGenreTypes && artist.value.activityGenreTypes.length > 0) {
    allInfo.value['Genre'] = [artist.value.activityGenreTypes
      .map(item =>
        artistActivityGenresMap[item].value !== 'ETC'
          ? artistActivityGenresMap[item].label
          : artist.value.activityGenreEtc
      )
      .join(', ')
    ];
  }

  // Solo Exhibition 처리 (여기서 <span> 태그 포함하여 처리)
  if (groupedExperiences.value['SOLO_EXHIBITION']) {
    allInfo.value['Solo Exhibition'] = groupedExperiences.value['SOLO_EXHIBITION'].map(
      (item) => {
        return `
          <span class="year">${item.year}</span>
          <span class="title">${item.title}</span>
          <span class="place">${item.location}</span>
          <span class="region">${item.region}</span>
        `;
      }
    );
  }


  if (groupedExperiences.value['GROUP_EXHIBITION']) {
    allInfo.value['Group Exhibition'] = groupedExperiences.value['GROUP_EXHIBITION'].map(
      (item) => {
        return `
          <span class="year">${item.year}</span>
          <span class="title">${item.title}</span>
          <span class="place">${item.location}</span>
          <span class="region">${item.region}</span>
        `;
      }
    );
  }

  if (artist.value.educations && artist.value.educations.length > 0) {
    allInfo.value['Education'] = artist.value.educations.map(
      (item) => {
        return `
          <span class="year">${item.year}</span>
          <span class="schoolName">${item.schoolName}</span>
          <span class="major">${item.major}</span>
          <span class="status"> ${artistEducationsStatusMap[item.status].label}</span>
        `;
      }
    );
  }

  if (groupedExperiences.value['RESIDENCY']) {
    allInfo.value['Residency'] = groupedExperiences.value['RESIDENCY'].map(
      (item) => {
        return `
          <span class="year">${item.year}</span>
          <span class="title">${item.title}</span>
          <span class="place">${item.location}</span>
          <span class="region">${item.region}</span>
        `;
      }
    );
  }

  if (groupedExperiences.value['WORKSHOP']) {
    allInfo.value['Workshop'] = groupedExperiences.value['WORKSHOP'].map(
      (item) => {
        return `
          <span class="year">${item.year}</span>
          <span class="title">${item.title}</span>
          <span class="place">${item.location}</span>
          <span class="region">${item.region}</span>
        `;
      }
    );
  }

  if (groupedExperiences.value['GRANT']) {
    allInfo.value['Grant'] = groupedExperiences.value['GRANT'].map(
      (item) => {
        return `
          <span class="year">${item.year}</span>
          <span class="title">${item.title}</span>
          <span class="place">${item.location}</span>
          <span class="region">${item.region}</span>
        `;
      }
    );
  }

  // 'Region' 추가
  let regionInfo = [];

  if (artist.value.domesticArea) {
    regionInfo.push(`
      <span class="place">국내</span>
      <span class="region">${artist.value.domesticArea}</span>
    `);
  }

  if (artist.value.overseasArea) {
    regionInfo.push(`
      <span class="place">해외</span>
      <span class="region">${artist.value.overseasArea}</span>
    `);
  }

  if (regionInfo.length > 0) {
    allInfo.value['Region'] = regionInfo;
  }

  if (artist.value.awards && artist.value.awards.length > 0) {
    allInfo.value['Award'] = artist.value.awards.map(item => {
      return `
        <span class="year">${item.year}</span>
        <span class="title">${item.title}</span>
      `;
    });
  }

  if (artist.value.collections && artist.value.collections.length > 0) {
    allInfo.value['Collection'] = artist.value.collections.map(item => {
      return `
         <span class="place">${item}</span>
      `;
    });
  }
}

const getEmbedVideoLink = (videoLink) => {
  if (!videoLink) {
    return ''; // 값이 없으면 빈 문자열이나 null 반환
  }

  // 1) YouTube 일반 링크 (예: https://www.youtube.com/watch?v=abcdef)
  //    쿼리 파라미터 v=값 추출
  const youtubeWatchRegex = /(?:youtube\.com\/watch\?v=)([^&]+)(.*)/;
  const youtubeShortRegex = /(?:youtu\.be\/)([^?]+)(.*)/;

  // 2) Vimeo 링크 (예: https://vimeo.com/12345678)
  const vimeoRegex = /(?:vimeo\.com\/)([^?]+)/;

  // 1. YouTube - watch 링크
  let match = videoLink.match(youtubeWatchRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // 2. YouTube - 단축 링크
  match = videoLink.match(youtubeShortRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://www.youtube.com/embed/${videoId}`;
  }

  // 3. Vimeo 링크
  match = videoLink.match(vimeoRegex);
  if (match && match[1]) {
    const videoId = match[1];
    return `https://player.vimeo.com/video/${videoId}`;
  }

  // url 이 아닌 경우 `현재url/{videoLink}` 화면을 iframe에 띄우면서 에러 발생.
  // url 아닌 경우 null 리턴. 
  try{  
    const url = new URL(videoLink);
  }catch (error){
    console.error("Invalid URL:", videoLink, error.message);
    return null;
  }

  // 4. 해당 사항 없으면 원본 링크 그대로 반환 or 처리
  return videoLink;
}

const fetchProfile = async () => {
  if (artistUuid.value) {
    try {
      const artistResponse = await apiClient.get(`/v1/contents/artists/${artistUuid.value}`);
      artist.value = artistResponse.data;
      groupedExperiences.value = artist.value.experiences.reduce((acc, experience) => {
        // 그룹화 기준: type
        const type = experience.type;

        // 해당 type이 처음이면 빈 배열 초기화
        if (!acc[type]) {
          acc[type] = [];
        }

        // 현재 experience를 해당 type에 추가
        acc[type].push(experience);

        return acc;
      }, {});
      setAllInfo();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
};

const fetchArtWorks = async (page = 0, append = false) => {
  try {
    if (artistUuid.value) {
      let api = `/v1/contents/artists/${artistUuid.value}/art-works?page=${page}&size=${size.value}`;
      if (selectedArtWorksCategory.value) {
        api += `&cUuid=${selectedArtWorksCategory.value.uuid}`;
      }
      const response = await apiClient.get(api);

      if (append) {
        artWorks.value = [...artWorks.value, ...response.data.content];
      } else {
        artWorks.value = response.data.content;
      }
      currentPage.value = response.data.pageable.pageNumber;
      totalPages.value = response.data.totalPages;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const loadMoreArtWorks = () => {
  if (currentPage.value < totalPages.value - 1) {
    fetchArtWorks(currentPage.value + 1, true);
  } else {
    fetchArtWorks(0);
    viewPanel.value.scrollIntoView({ behavior: "smooth", });
  }
};

const fetchArtWorksCategories = async () => {
  try {
    const response = await apiClient.get(`/v1/contents/artists/${artistUuid.value}/art-works/categories`);
    artWorksCategories.value = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

const setCategory = (category) => {
  if (category) {
    if (!selectedArtWorksCategory.value || selectedArtWorksCategory.value.uuid !== category.uuid) {
      selectedArtWorksCategory.value = category;
      fetchArtWorks(0);
    }
  } else {
    if (selectedArtWorksCategory.value) {
      selectedArtWorksCategory.value = null;
      fetchArtWorks(0);
    }
  }
};

const toggleLike = async (event, artistUuid, index) => {
  if (!authStore.status.loggedIn) {
    alert("로그인 후 이용 하실 수 있습니다.");
    return;
  }
  const button = event.target.closest('button');
  const isLiked = button.classList.contains("on");
  const msg = !isLiked ? "관심 아티스트 등록" : "관심 아티스트 삭제";
  if (confirm(msg + " 하시겠습니까?")) {
    try {
      if (!isLiked) {
        await apiClient.post('/v1/users/me/likes', {
          type: "ARTIST",
          uuid: artistUuid,
        });
      } else {
        await apiClient.delete('/v1/users/me/likes', {
          data: {
            type: "ARTIST",
            uuid: artistUuid,
          },
        });
      }

      artists.value[index].isLike = !isLiked;
    } catch (error) {
      console.error("Error fetching artist data:", error);
    }
  }
}

const checkAndOpenArtWorkModal = async (newValue, targetUuid) => {
  const exists = newValue.some(item => item.uuid === targetUuid);
  if (!exists) {
    if (currentPage.value < totalPages.value - 1) {
      await fetchArtWorks(currentPage.value + 1, true);
    }
  } else {
    openArtWorkModal(targetUuid);
  }
};


const fetchNextPageIfNeeded = async (targetUuid) => {
  if (currentPage.value < totalPages.value - 1) {
    await fetchArtWorks(currentPage.value + 1, true);
  } else {
    alert(`No more pages to load for uuid: ${targetUuid}`);
  }
};

const openArtWorkIfExists = (newValue, targetUuid) => {
  const exists = newValue.some(item => item.uuid === targetUuid);
  if (exists) {
    openArtWorkModal(targetUuid);
  } else {
    fetchNextPageIfNeeded(targetUuid);
  }
};

onMounted(() => {
  fetchProfile();
  fetchArtWorksCategories();
  fetchArtWorks();
  if (route.query.artwork) {
    watch(() => artWorks.value, async (newValue) => {
      await openArtWorkIfExists(newValue, route.query.artwork);
    });
  }
});
</script>

<style scoped>
/* 애니메이션 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

ul.favorite li img {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* 비율 유지하면서 이미지가 잘리도록 */
}

ul.favorite li {
  position: relative;
  /* width: calc(25% - 14px); */
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

/* .art-artist .artist-profile .profile-box .career .col2 dl:nth-child(n+3) {display: none;} */
/* .art-artist .artist-profile .profile-box .career .col2 dl dd ul li:nth-child(n+4) {display: none;} */
</style>
