import Notice from "@/views/support/Notice.vue";
import Inquiry from "@/views/support/Inquiry.vue";
import NoticeDetail from "@/views/support/NoticeDetail.vue";
import InquiryDetail from "@/views/support/InquiryDetail.vue";
import InquiryCreate from "@/views/support/InquiryCreate.vue";
import FAQ from "@/views/support/FAQ.vue";

export const supportRoutes = [
    {
        path: '/my/support/notice',
        name: 'Notice',
        component: Notice,
    },
    {
        path: '/my/support/notice/:uuid',
        name: 'NoticeDetail',
        component: NoticeDetail,
        props: true
    },
    {
        path: '/my/support/inquiry',
        name: 'Inquiry',
        component: Inquiry,
    },
    {
        path: '/my/support/inquiry/:uuid',
        name: 'InquiryDetail',
        component: InquiryDetail,
        props: true
    },
    {
        path: '/my/support/inquiry/create',
        name: 'InquiryCreate',
        component: InquiryCreate,
    },
    {
        path: '/my/support/faq',
        name: 'FAQ',
        component: FAQ,
    },
];
