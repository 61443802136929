<template>
  <!-- s::PAGE -->
  <div v-if="loading" class="loading"></div>

  <div v-else-if="error" class="error">{{ error.message }}</div>

  <div v-else class="contents">
    <!-- s:: ARTICLE -->
    <div class="article">
      <div class="layout">
        <div class="box">
          <ArticleSnb />
          <div class="content-panel">
            <div class="search-wrap">
              <SearchBar v-model="searchQuery" placeholder="검색어" @submit="onSearch" />
            </div>
            <div class="list-type1 event">
              <ul>
                <li v-for="(item, index) in events" :key="index">
                  <ArticleCard :item="item"
                    :to="{ name: 'EventDetail', params: { uuid: item.uuid }, query: { page: currentPage, q: searchQuery } }" />
                </li>
              </ul>
            </div>
            <Pagination :current-page="currentPage" :total-pages="totalPages"
              :visible-count="5" @goToPage="goToPage" />
            <InfiniteScroll :on-reach-end="goToPage" :current-page="currentPage"
              :total-pages="totalPages" :is-fetching="isFetching" />
          </div>
        </div>
      </div>
    </div>
    <!-- e:: ARTICLE -->
  </div>
  <!-- e::PAGE -->
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { apiClient } from "@/services/auth-header";
import ArticleSnb from "@/components/snb/ArticleSnb.vue";
import SearchBar from "@/components/ui/SearchBar.vue";
import Pagination from "@/components/ui/Pagination.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll.vue";
import ArticleCard from "@/components/article/ArticleCard.vue";

const route = useRoute();

const loading = ref(true);
const error = ref(null);
const events = ref([]);

// 페이지 정보
const searchQuery = ref(route.query.q || "");
const currentPage = ref(Number(route.query.page || 0));
const size = ref(20);
const totalPages = ref(0);
const isFetching = ref(false);

async function fetchEvents(page = 0, append = false) {
  if (isFetching.value) return;
  isFetching.value = true;

  try {
    const response = await apiClient.get(`/v1/contents/articles/events?q=${searchQuery.value}&page=${page}&size=${size.value}`);
    const newData = response.data.content;

    if (append) {
      events.value.push(...newData);
    } else {
      events.value = newData;
    }

    currentPage.value = response.data.pageable.pageNumber;
    totalPages.value = response.data.totalPages;
  } catch (err) {
    error.value = err;
  } finally {
    isFetching.value = false;
    loading.value = false;
  }
}

function goToPage(page = 0, append = false) {
  fetchEvents(page,append);
}

function onSearch(query) {
  searchQuery.value = query;
  currentPage.value = 0;   // 검색 시 첫 페이지부터 다시 로딩
  fetchEvents(0);
}

onMounted(async () => {
  await fetchEvents(currentPage.value);
});
</script>

<style scoped>
@media all and (max-width:768px) {
  .article .list-type1.event>ul {
    flex-wrap: wrap;
    overflow-x: hidden;
    width: 100%;
    padding-bottom: 0;
  }

  .article .list-type1.event>ul li:last-child {
    margin-right: 0;
  }

  .article .list-type1.event>ul li {
    min-width: 48%;
  }
}
</style>